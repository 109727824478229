import React from 'react'
import { Link } from 'react-router-dom'

const SellerForm = () => {
    return (
        <>
            <form>
                <input type="text" id="name" className="max-w-[49%] h-[60px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-6 mb-8" placeholder="Full Name*" required
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
                />
                <input type="email" id="emailid" className="max-w-[49%] ml-[2%] h-[60px] border-[2px] border-[#e9e9e9] bg-[#e9e9e9] outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-6 mb-8" placeholder="Email*" required
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
                />
                <input type="tel" id="tel" className=" h-[60px] border-[2px] border-[#e9e9e9] bg-[#e9e9e9] outline-none focus-within:border-primary transition-all text-[#000] text-sm w-full p-6 mb-8" placeholder="Contact No.*" required
                // value={password}
                // onChange={(e) => setPassword(e.target.value)}
                />

                <input className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" required
                    id="terms"
                    type="checkbox"
                    name="checkbox"
                />

                <label htmlFor="terms" className="ml-2 text-sm font-medium text-[#5C727D]" >I accept all the <Link to="/terms-policies" className="text-primary hover:underline">terms and conditions</Link> and want to become a SWATEE direct seller</label><br /><br />

                {/* {error && <p className='text-[red] text-sm mt-[-20px] pb-6'>{error}</p>} */}
                <button type="submit" className="text-white text-xl bg-primary px-12 py-4">Submit  </button>
            </form>
        </>
    )
}

export default SellerForm