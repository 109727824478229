import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { services } from "../../services";
import axios from "axios";
import swal from "sweetalert";

const AccountDetails = () => {
  const formRef = useRef();

  const [userDataMain, setUserDataMain] = useState([]);
  const [initialData, setInitialData] = useState({});

  const getUser = async () => {
    try {
      const response = await axios.get(services.getUsers);
      if (response?.status !== "error") {
        // console.log(response?.data?.data[0]);
        setUserDataMain(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const defaultAddress = {
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
  };

  useEffect(() => {
    getUser();
  }, []);

  // fetching user Data
  useEffect(() => {
    setInitialData({
      ...defaultAddress,
      first_name: userDataMain[0]?.first_name,
      last_name: userDataMain[0]?.last_name,
      mobile: userDataMain[0]?.mobile,
      email: userDataMain[0]?.email,
    });
  }, [userDataMain]);

  const billingForm = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Name is Required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Please enter correct email"),
    tel: Yup.number()
      .typeError("Must be a number type")
      .required("Contact No is Required"),
    alt_tel: Yup.number().typeError("Must be a number type"),
  });

  const updateUserProfile = async (formData) => {
    try {
      const response = await axios.post(services.updateUser, formData);
      swal("Profile Updated successfully.");
      navigate("/myaccount");
    } catch (error) {
      console.log("Error updating user address:", error);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialData}
        validationSchema={billingForm}
        onSubmit={(values) => {
          // same shape as initial values
          //console.log(values);
          updateUserProfile(values);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="errorName">
              {/* Personal Details */}
              {errors.full_name && touched.full_name ? (
                <div className="errorList">{errors.full_name}</div>
              ) : null}
              {errors.last_name && touched.last_name ? (
                <div className="errorList">{errors.last_name}</div>
              ) : null}
              {errors.mobile && touched.mobile ? (
                <div className="errorList">{errors.mobile}</div>
              ) : null}
              {errors.email && touched.email ? (
                <div className="errorList">{errors.email}</div>
              ) : null}
            </div>

            {/* Personal Information */}
            <div>
              <h3 className="py-6">Personal Information</h3>
              <Field
                type="text"
                //   id="name"
                name="first_name"
                className="max-w-[49%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                placeholder="Full Name*"
                required
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
              ></Field>

              <Field
                type="text"
                name="last_name"
                //   id="emailid"
                className="max-w-[49%] ml-[2%] h-[40px] border-[2px] border-[#e9e9e9] bg-[#e9e9e9] outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                placeholder="Email*"
                required
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
              />

              <Field
                type="tel"
                //   id="tel"
                name="mobile"
                className="max-w-[49%] h-[40px] border-[2px] border-[#e9e9e9] bg-[#e9e9e9] outlipayment_methodne-none focus-within:border-primary transition-all text-[#000] text-sm w-full p-2 mb-6"
                placeholder="Contact No.*"
                required
                // value={password}
                // onChange={(e) => setPassword(e.target.value)}
              />

              <Field
                type="email"
                name="email"
                //   id="emailid"
                className="max-w-[49%] ml-[2%] h-[40px] border-[2px] border-[#e9e9e9] bg-[#e9e9e9] outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                placeholder="Email*"
                required
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button className="btn">Update Profile</button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AccountDetails;
