import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineShoppingCart, AiOutlineSearch, AiOutlineHeart } from 'react-icons/ai';
import { services } from '../../services';
import { useSelector } from 'react-redux';
import axios from 'axios';
const Profile = () => {

    const [userData, setUserData] = useState({});
    const isLoggedIn = localStorage.getItem("token");
    let navigate = useNavigate();
    const data = useSelector((state) => state.cartReducer);

    useEffect(() => {
        if (isLoggedIn) {
            axios.get(services.getUsers).then(res => {
                // console.log(res.data.data);
                setUserData(res?.data?.data);
            }).catch(e => {
                console.log(e);
            });
        }
    }, [isLoggedIn]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    return (
        <>
            <ul className="text-[white] flex justify-end text-sm sm:py-2 sm:justify-center">
                <li
                    className={`relative px-4 ${isDropdownOpen ? 'underline' : ''}`}
                    onClick={toggleDropdown}
                >
                    My Profile
                    {isDropdownOpen && (
                        <ul className="absolute left-0 top-full bg-white py-2 mt-2">
                            {/* Submenu: My Account */}
                            {isLoggedIn && (
                                <li>
                                    <Link to="/myaccount" className="block px-4 underline">
                                        My Account
                                    </Link>
                                </li>
                            )}

                            {/* Submenu: Logout */}
                            {isLoggedIn && (
                                <li>
                                    <a
                                        href="#"
                                        onClick={() => {
                                            localStorage.removeItem("token");
                                            navigate("/login");
                                        }}
                                        className="block px-4 underline cursor-pointer"
                                    >
                                        Logout
                                    </a>
                                </li>
                            )}
                        </ul>
                    )}
                </li>

                {/* ... */}
            </ul>
        </>
    )
}

export default Profile
