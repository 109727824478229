let  initialState={
     cart:[],
     loader:false,
     cartCalculation:{},
    };

const cartReducer = (state=initialState,action)=>{
    switch(action.type){
        case 'CART_ADD' :
        return  {
             ...state,
            cart:[...state.cart,action.payload]
        }
        case 'CART_REMOVE' :
        return  {
            ...state,
            cart:action.payload
        }
        case 'LOADER' :
            return  {
                ...state,
                loader:action.payload
            }
        case 'CART_CALULATION' :
            return  {
                ...state,
                cartCalculation:action.payload
            } 
            case 'CART_COUPON' :
                return  {
                    ...state,
                    coupon:action.payload
                } 
        default:
        return state;
        
    }
}

export default cartReducer;