import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AiOutlineMinus, AiOutlinePlus, AiOutlineDelete } from "react-icons/ai";
import TitleBar from "../title/TitleBar";
import productimage from "../../assets/aloe-vera.jpeg";
import CartTotal from "./CartTotal";
import ProductQuantity from "../products/ProductQuantity";
import { useDispatch, useSelector } from "react-redux";
import { cartCalculation, cartCouponData, cartDataRemove } from "../../actions";
import { services } from "../../services";
// import { ShoppingCartContext } from '../context/shoppingCartContext';

// GET CART DATA API
//const getcartURL = "/get-cart-data";
const image_base_URL = 'https://admin.swatee.in/uploads/product_images/';

const Cart = () => {
  //const [deleteCartData, setDeleteCartData] = useState(null);
  //const [newUpdatedData, setNewUpdatedData] = useState([]);
  const inputref = useRef();
  const data = useSelector((state) => state.cartReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [couponData, setCouponData] = useState({});
  const [couponMessage, setCouponMessage] = useState('');
  // Calling API again for rendering new data in cart after deletation by making a const variable fetchData
  // const fetchData = () => {
  //     axios.get(getcartURL)
  //         .then(response => {
  //             if (response.data.status !== 'error') {handleCouponData
  //                 setCartData(Object.assign(response.data.data));
  //             } else {
  //                 setCartData([])
  //                 changeCartCount();
  //             }

  //         })
  //         .catch(error => {
  //             console.log(error);
  //         });
  // };

  let datacartCalcuation = {};
  useEffect(() => {
    let totalCartPaymentProces = 0;
    data?.cart.map((item) => {
      totalCartPaymentProces += parseInt(
        item?.newUpdatedPrice
          ? item?.newUpdatedPrice
          : item?.product_sale_price
            ? item?.product_sale_price
            : item?.product_regular_price
      );
    });
    datacartCalcuation = { total: totalCartPaymentProces };

  }, [data]);

  const udpateThePrice = (itemQuantity, item) => {
    let price =
      (item?.product_sale_price
        ? item?.product_sale_price
        : item?.product_regular_price) * itemQuantity;
    let newCartData = data?.cart.map((product) => {
      if (item.id === product.id) {
        return {
          ...product,
          newQuantityAdded: itemQuantity,
          newUpdatedPrice: price,
        };
      } else {
        return {
          ...product,
        };
      }
    });
    dispatch(cartDataRemove(newCartData));
  };

  // Delete Products From Cart

  const handleDeleteClick = (productId) => {
    let previousProductInState = data.cart;
    console.log(previousProductInState);
    let productAfterRemove = previousProductInState.filter((item) => {
      if (productId !== item.id) {
        return item;
      }
    });
    console.log(productAfterRemove, ">>>>>>>>>>>>>>>>");
    dispatch(cartDataRemove(productAfterRemove));
  };

  const handleCouponData = () => {

    let inputData = inputref.current?.value;
    let payloadForCoupon = {
      coupon_name: inputData
    }

    axios.get(`${services.getCoupon}?coupon_name=${inputData}`)
      .then(response => {
        console.log(response);
        if (response?.data?.status === 'success') {
          let couponMain = {
            "id": response?.data?.data?.id
          };
          setCouponData(couponMain);
          dispatch(cartCouponData(response?.data?.data));

        }
        setCouponMessage(response?.data?.message);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const cartDetails = () => {
    dispatch(cartCalculation(datacartCalcuation));
    let tokenval = localStorage.getItem("token");
    if (tokenval === null) {
      navigate("/login");
    } else {
      navigate("/checkout");
    }
  };

  useEffect(() => {
    dispatch(cartCouponData({}));
  }, [])
  return (
    <>
      <TitleBar
        pagetitle="Cart"
        breadcrumbs={
          <>
            <Link to="/">Home</Link> &gt;
            <Link to=""> Cart</Link>
          </>
        }
      />

      <div className="container px-4 py-8 flex items-center justify-between mt-4 mobile:flex-col border-b-2">
        <Link to="/shop">
          <button className="btn bg-white text-primary border-b border-primary mt-0">
            Continue Shopping
          </button>
        </Link>
        {/* <Link to="/checkout"> */}
        <button className="btn transition duration-0 hover:duration-700 ease-in-out mt-0" onClick={() => cartDetails()} >
          Checkout Now
        </button>
        {/* </Link> */}
      </div>

      {/* Description Area */}
      <div className="container py-8 sm:py-2 flex my-12 sm:my-0 sm:flex-col lg:flex-row">
        <div className="basis-[70%] ">
          {/* {getCart.length < 0 && getCart.map(item => ( */}

          {/* <table className="w-full text-left table-auto">
            <thead className="border-b">
              <tr className="border-b">
                <th className="p-2">Image</th>
                <th className="p-2">Product Name</th>
                <th className="p-2">Quantity</th>
                <th className="p-2">Price</th>
                <th className="p-2">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b">
                <td className="p-2">The Sliding Mr. Bones (Next Stop, Pottersville)</td>
                <td className="p-2">Malcolm Lockyer</td>
                <td className="p-2">1961</td>
              </tr>
            </tbody>
          </table> */}

          {data?.cart?.length > 0 ? (
            data?.cart.map((item) => (
              <div
                key={item.id}
                className="flex justify-between items-center px-6 py-4 border-b"
              >
                <div className="basis-[10%]">
                  <img className="max-w-[60px]" src={`${image_base_URL}${item.product_image}`} alt="" />
                </div>
                <div className="basis-[40%] sm:basis-[30%]">
                  <p className="sm:text-[16px] font-bold lowercase">{item.product_name}</p>
                </div>

                <div className="flex items-center justify-center basis-[20%]">
                  <ProductQuantity
                    item={item}
                    udpateThePrice={udpateThePrice}
                  />
                </div>
                <div className="basis-[10%] sm:basis-[15%]">
                  <p>
                    ₹{" "}
                    {item?.newUpdatedPrice
                      ? item?.newUpdatedPrice
                      : item?.product_sale_price
                        ? item?.product_sale_price
                        : item?.product_regular_price}{" "}
                  </p>
                </div>

                {/* Delete From Cart API CALL */}
                <div className="basis-[10%] sm:basis-[2%] cursor-pointer">
                  <AiOutlineDelete
                    className="text-[red]"
                    onClick={() => handleDeleteClick(item.id)}
                  // onClick={() => deleteProductFromCart(item.id)}
                  />
                </div>
              </div>
            ))
          ) : (
            <p className="text-center py-[10%] text-3xl">Cart is empty.</p>
          )}
          {/* Coupon Apply */}
          <div className="my-8">
            <input
              type="text"
              id="coupon"
              name="coupon"
              className="max-w-[30%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2"
              placeholder="Enter Coupon detail"
              required1
              ref={inputref}
            />
            <button
              type="Apply"
              className="text-white text-md bg-primary px-8 py-2"
              onClick={() => { (handleCouponData()) }}
            >
              APPLY{" "}
            </button>
            {couponMessage}
          </div>
        </div>

        {/* Product Total Amount section */}
        <div className="basis-[30%] ml-8 p-2 sm:mx-4 sm:my-12">
          <h3 className="pb-6">Cart Totals</h3>
          <CartTotal couponData={couponData} />
          <a className=" px-5 py-2.5 relative group overflow-hidADD TO CART1
          den font-medium bg-primary text-white text-center block border border-primary cursor-pointer"
            onClick={() => cartDetails()}
          >
            <span className="absolute top-0 left-0 flex w-[0] h-full mb-0 transition-all duration-700 ease-out transform translate-y-0 bg-white group-hover:w-full "></span>
            <span className="relative group-hover:text-primary">
              Proceed To Checkout
            </span>
          </a>
          {/* </Link> */}
        </div>
      </div>
    </>
  );
};

export default Cart;
