import React, { useState, useEffect } from 'react'
import axios from 'axios'
// import productImg from '../../assets/aloe-vera.jpeg'
import { services } from '../../services';
import { Link } from 'react-router-dom';



// Product Category API 
const categoryURL = "/get-categories?featured=true";

const HomeCategories = () => {
    const [category, setCategory] = useState([]);
    useEffect(() => {
        axios.get(categoryURL)
            .then(response => {
                setCategory(response.data.data);
                console.log(response.data.data)
            })

            .catch(error => {
                console.log(error);
            });
    }, []);

    return (
        <>

            {category.map(item => (

                <div key={item.id} className='flex items center justify-between mb-2 text-md hover:text-primary' link>
                    <Link
                        to={`${services.getCategory}/${item.id}`}

                    >
                        <img className='' src={`${services.image_base_URL}${item.category_image}`} alt="" />
                    </Link>
                </div>

            ))}
        </>
    )
}

export default HomeCategories