import React from 'react'
import { Link } from 'react-router-dom';
// import logo from '../assets/logo.jpeg'
import aloevera from '../assets/aloe-vera.jpeg'
import TitleBar from '../component/title/TitleBar'

const CodeConduct = () => {
    return (
        <>
            <TitleBar pagetitle="Model Code of Conduct" breadcrumbs={
                <>
                    <Link to="/">Home</Link> &gt;
                    <Link to=""> Model Code of Conduct</Link>
                </>}
            />

            {/* About swatee pvt. ltd */}
            <div className='container px-4 my-6 flex sm:flex-col lg:flex-row'>
                <div className='basis-[75%] py-6 px-8 mt-8 bg-[#f2f2f2]'>
                    {/* <img className='w-[40%]' src={logo} alt="/Swatee Logo" /> */}
                    <h2 className='py-6 text-lg'>SHS आचार संहिता </h2>
                    <ol className="text-[#4d4d4d] p-4 list-decimal text-sm">

                        <li className='p-1'>
                            कंपनी से सम्बंधित कोई भी सदस्य कोई भी गैर क़ानूनी कार्य नहीं करेंगें.
                        </li>

                        <li className='p-1'>
                            कोई भी सदस्य / प्रत्यक्ष विक्रेता (DS) कंपनी का कोई भी कार्य कंपनी द्वारा जारी आईडी कार्ड के बिना नहीं करेगा.
                        </li>

                        <li className='p-1'>
                            जब भी आप किसी सम्भावना से मिलते हैं तो अपने और कंपनी के बारे में जानकारी देंगे. तब उत्पाद के विषय में सम्पूर्ण जानकारी देंगे.
                        </li>

                        <li className='p-1'>
                            आप केवल उपभोक्ता को ही सदस्य / प्रत्यक्ष विक्रेता (DS) बना सकते हैं.
                        </li>

                        <li className='p-1'>
                            आप उपभोक्ता को या आपके द्वारा बनाये सदस्य / प्रत्यक्ष विक्रेता (DS) को प्रत्यक्ष या अप्रत्यक्ष रूप से किसी प्रकार का कोई लालच नहीं देंगें न ही कोई झूठा वादा करेंगें.
                        </li>

                        <li className='p-1'>
                            आप अपने किसी भी सदस्य / प्रत्यक्ष विक्रेता (DS) को निश्चित उत्पाद बिक्री के लिए बाध्य नहीं करेंगें.
                        </li>

                        <li className='p-1'>
                            सदस्य / प्रत्यक्ष विक्रेता (DS) का लाभांश प्रतिशत व बोनस प्रतिशत उसके और उसके ग्रुप द्वारा की गयी बिक्री के आधार पर ही तय होगा.
                        </li>

                        <li className='p-1'>
                            उपभोक्ता को सदस्यता देते समय ही उत्पाद, उत्पाद बिक्री पर उसे मिलने वाले लाभांश या अन्य लाभ के विषय में स्पष्ट रूप से अवगत कराना अनिवार्य होगा.
                        </li>

                        <li className='p-1'>
                            सदस्य को कंपनी के उत्पाद कंपनी के खाते में धनराशी जमा करके कम्पनी के ऑफिस से प्राप्त करने होंगे.
                        </li>

                        <li className='p-1'>
                            कंपनी सदस्य / प्रत्यक्ष विक्रेता (DS) के किसी भी व्यय के प्रति उत्तरदायी नहीं होगी.
                        </li>

                        <li className='p-1'>
                            लाभांश धनराशी को सदस्य / प्रत्यक्ष विक्रेता (DS) के बैंक खाते में ही तय आधार पर ट्रांसफर किया जायेगा.
                        </li>

                        <li className='p-1'>
                            डायरेक्ट सेलर का लाभांश नियमानुसार टी०डी०एस० (TDS) काटकर ही दिया जायेगा.
                        </li>

                        <li className='p-1'>
                            सदस्य / उपभोक्ता की कोई शिकायत हो तो उसके निस्तारण और समाधान हेतु उसकी शिकायत तुरंत शिकायत निस्तारण समिति को अपनी उपलाइन के माध्यम से लिखित रूप से भेजना अनिवार्य होगा.
                        </li>

                        <li className='p-1'>
                            यदि कोई सदस्य / प्रत्यक्ष विक्रेता (DS) कंपनी की सदस्यता छोड़ता हैं तो उसे कंपनी को ३० दिन पुर्व लिखित रूप से सूचित करना होगा और इस अवधि में अपने सभी लेनदेन पूरे करने होंगें.
                        </li>

                        <li className='p-1'>
                            प्रत्येक सदस्य / प्रत्यक्ष विक्रेता (DS) को कम्पनी द्वारा आयोजित सेमिनार में प्रतिभाग करना होगा.
                        </li>

                    </ol >
                    <p className='text-justify text-sm p-2 text-white  bg-primary'>
                        प्रत्येक सदस्य / प्रत्यक्ष विक्रेता को उक्त नियमों व शर्तों का अनिवार्य रूप से पालन करना होगा.
                    </p>
                </div>
                <div className='basis-[25%] h-[450px] ml-4 mt-8 bg-[#f2f2f2] '>
                    <h2 className='py-2 sm:text-3xl text-lg text-white text-center bg-primary'>
                        New Arrivals
                    </h2>

                    <img className='sm:max-w-[90%] mx-auto' src={aloevera} alt="/product-image" />
                </div>
            </div>
        </>
    )
}

export default CodeConduct