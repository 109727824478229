import { Routes, Route, BrowserRouter } from "react-router-dom";

import Home from './pages/Home'
import About from './pages/About'
import Shop from './pages/Shop'
import IncomePlans from './pages/IncomePlans'
import Gallery from './pages/Gallery'
import Contact from './pages/Contact'
import Services from "./pages/Services";
import Login from "./component/login/Login";
import Cart from "./component/cart/Cart";
import Register from "./component/register/Register";
import ForgotPass from "./component/forgotpass/ForgotPass";
import ProductsDetails from "./component/productsdetails/ProductsDetails";
import Offers from "./pages/Offers";
import WhishList from "./pages/WhishList";
import ShoppingCart from "./component/cart/ShoppingCart";
import Header from './component/global/Header';
import Footer from './component/global/Footer';
import TermPolicies from "./terms/TermPolicies";
import DirectSeller from "./terms/DirectSeller";
import BonusDividents from "./terms/BonusDividents";
import PromotionPolicies from "./terms/PromotionPolicies";
import CodeConduct from "./terms/CodeConduct";
import CustomerRelations from "./terms/CustomerRelations";
import ComplaintsRedressal from "./terms/ComplaintsRedressal";
import ReturnPolicy from "./terms/ReturnPolicy";
import ShippingPolicy from "./terms/ShippingPolicy";
import Redressals from "./terms/Redressals";
import MyAccount from "./component/myaccount/MyAccount";
import LoadingSpinner from "./component/actions/LoadingSpinner";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { globalLoader } from "./actions";
import JyotishPeeth from "./servicepages/JyotishPeeth";
import SwateeYogpeeth from "./servicepages/SwateeYogpeeth";
import AyurJyoti from "./servicepages/AyurJyoti";
import HumanDigestiveSystem from "./blogs/HumanDigestiveSystem";
import WomensHealth from "./blogs/WomensHealth";
import HealthyLiver from "./blogs/HealthyLiver";
import NewCheckout from "./component/Checkout/NewCheckout";
import ThankYou from "./pages/ThankYou";
import Catalogue from "./pages/Catalogue";

const Routeslist = () => {
  const data = useSelector((state) => state.cartReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(globalLoader(true))
    setTimeout(() => { dispatch(globalLoader(false)) }, 3000);
  }, [])
  return (
    <>
      {data?.loader && (
        <div className="loadercustomization">
          <div className="loadercustomizationmain">
            <LoadingSpinner />
          </div>
        </div>
      )}
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/shop" element={<Shop />} />
        <Route exact path="/incomeplans" element={<IncomePlans />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/offers" element={<Offers />} />
        <Route exact path="/catalogue" element={<Catalogue />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/cart" element={<Cart />} />
        <Route exact path="/whishlist" element={<WhishList />} />
        <Route exact path="/checkout" element={<NewCheckout />} />
        <Route exact path="/thankyou" element={<ThankYou />} />

        <Route
          exact
          path="/product-details/:id"
          element={<ProductsDetails />}
        />

        <Route
          exact
          path="/product-category/:id"
          element={<Shop />}
        />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/forgot" element={<ForgotPass />} />
        <Route exact path="/shoping-cart" element={<ShoppingCart />} />
        <Route exact path="/myaccount" element={<MyAccount />} />

        {/* Service Pages */}
        <Route exact path="/swatee-jyotish-peeth" element={<JyotishPeeth />} />
        <Route exact path="/swatee-yogpeeth" element={<SwateeYogpeeth />} />
        <Route exact path="/swatee-ayur-jyoti" element={<AyurJyoti />} />

        {/* Terms Pages */}

        <Route exact path="/terms-policies" element={<TermPolicies />} />
        <Route exact path="/direct-seller-terms" element={<DirectSeller />} />
        <Route
          exact
          path="/bonus-and-dividends"
          element={<BonusDividents />}
        />
        <Route
          exact
          path="/promotion-policies"
          element={<PromotionPolicies />}
        />
        <Route
          exact
          path="/model-code-of-conduct"
          element={<CodeConduct />}
        />
        <Route
          exact
          path="/regarding-customer-relations"
          element={<CustomerRelations />}
        />
        <Route
          exact
          path="/complaints-redressal-policy"
          element={<ComplaintsRedressal />}
        />
        <Route exact path="/return-policy" element={<ReturnPolicy />} />
        <Route exact path="/shipping-policy" element={<ShippingPolicy />} />
        <Route
          exact
          path="/complaints-and-redressals"
          element={<Redressals />}
        />

        {/* Blog Pages */}
        <Route exact path="/human-digestive-system" element={<HumanDigestiveSystem />} />
        <Route exact path="/womens-health" element={<WomensHealth />} />
        <Route exact path="/ways-to-keep-lever-healthy" element={<HealthyLiver />} />


      </Routes>
      <Footer />
    </>
  );
}

export default Routeslist
