import React, { useState, useEffect, useRef } from 'react';
import Logo from '../../assets/logo.jpeg';
import { Link, useLocation } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose, AiOutlineShoppingCart } from 'react-icons/ai';
import { RiArrowDropDownLine } from 'react-icons/ri';
import TopBar from './TopBar';
import '../../global.css'

const Header = () => {
    const [toggle, setToggle] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    const handleServiceLinkClick = () => {
        setShowDropdown(!showDropdown);
    }
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname])
    // useEffect(() => {
    //     // Attach event listener to document object
    //     document.addEventListener('click', handleClickOutside);

    //     // Clean up function to remove event listener
    //     return () => {
    //         document.removeEventListener('click', handleClickOutside);
    //     };
    // }, []);

    const handleClickOutside = (event) => {
        // Close submenu if clicked outside of it
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    return (
        <>

            <TopBar />
            <div className='bg-[#fff] drop-shadow-md'>

                <div className="container flex justify-between items-center font-semibold px-4 ">

                    {/* LOGO */}
                    <div>
                        <Link to="/"><img className='w-48 py-2 sm:w-24' src={Logo} alt="/" /></Link>
                    </div>


                    {/* desktop navigation */}
                    <div className='md:flex sm:hidden'>
                        <ul className="text-[#4d4d4d] flex text-md">
                            <Link to="/" >
                                <li className='p-4'>Home</li>
                            </Link>
                            <Link to="/about">
                                <li className='p-4'>About Us </li>
                            </Link>
                            <Link to="/shop">
                                <li className='p-4'>Shop</li>
                            </Link>
                            <Link to="/incomeplans" >
                                <li className='p-4'>Income Plans</li>
                            </Link>
                            <Link to="#" className={`relative ${showDropdown ? 'text-primary' : 'text-[#4d4d4d]'}`} onClick={handleServiceLinkClick}>

                                <li className='p-4'>
                                    Services <RiArrowDropDownLine className='inline-block text-2xl' />
                                    {showDropdown &&
                                        <ul ref={dropdownRef} className="w-[200px] absolute top-full left-0 mt-2 py-2 bg-white rounded-lg z-10 text-[#4d4d4d] text-sm shadow-2xl ">
                                            <Link to="/swatee-jyotish-peeth">
                                                <li className='px-4 py-2 hover:bg-gray-100 hover:text-primary border-b'>Swatee Jyotish Peeth</li>
                                            </Link>
                                            <Link to="/swatee-yogpeeth">
                                                <li className='px-4 py-2 hover:bg-gray-100 hover:text-primary border-b'>Swatee YogPeeth</li>
                                            </Link>
                                            <Link to="/swatee-ayur-jyoti">
                                                <li className='px-4 py-2 hover:bg-gray-100 hover:text-primary'>Swatee Ayur Jyoti</li>
                                            </Link>
                                        </ul>
                                    }
                                </li>
                            </Link>
                            <Link to="/gallery">
                                <li className='p-4'>Gallery</li>
                            </Link>
                            <Link to="/contact">
                                <li className='p-4'>Contact Us</li>
                            </Link>
                            <Link to="/offers">
                            {/* <li className='px-4 py-2 mt-2 rounded-lg text-sm bg-primary text-white sm:w-[150px] sm:m-auto animated-button'>Offers</li> */}
                            <li className='animate-charcter px-4 py-[5px]'>Offers</li>
                            </Link>
                            <Link to="/catalogue">
                                <li className='px-4 py-2 mt-2 ml-4 text-sm rounded-lg bg-primary text-white sm:w-[150px] sm:m-auto'>Catalogue</li>
                            </Link>
                        </ul>
                    </div>
                    {/* mobile responsive navigation */}

                    <div className={`duration-700 md:hidden lg:hidden xl:hidden 2xl:hidden max-w-[100%] block fixed top-[40px] bg-white w-full h-full sm:z-[99999]
                    ${toggle ? `left-[0]` : `left-[-100%]`}
                    `}>
                        <ul className="text-[#4d4d4d] text-md sm:text-center pb-8 pt-4 bg-white">
                            <Link to="/" onClick={() => setToggle(false)}>
                                <li className='p-3'>Home</li>
                            </Link>
                            <Link to="/about" onClick={() => setToggle(false)}>
                                <li className='p-3'>About Us </li>
                            </Link>
                            <Link to="/shop" onClick={() => setToggle(false)}>
                                <li className='p-3'>Shop</li>
                            </Link>
                            <Link to="/incomeplans" onClick={() => setToggle(false)}>
                                <li className='p-3'>Income Plans</li>
                            </Link>
                            {/* <Link to="#" className='relative' onClick={() => setToggle(false)}>
                                <li className='p-4'>
                                    Services
                                </li>
                            </Link> */}
                            <Link to="#" className={`relative ${showDropdown ? 'text-primary' : 'text-[#4d4d4d]'}`} onClick={handleServiceLinkClick}>

                                <li className='p-4'>
                                    Services <RiArrowDropDownLine className='inline-block text-2xl' />
                                    {showDropdown &&
                                        <ul ref={dropdownRef} className="w-[200px] m-auto py-2 bg-white rounded-lg  z-10 text-[#4d4d4d] text-sm shadow-2xl">
                                            <Link to="/swatee-jyotish-peeth">
                                                <li className='px-4 py-2 hover:bg-gray-100 hover:text-primary border-b' onClick={() => setToggle(false)}>Swatee Jyotish Peeth</li>
                                            </Link>
                                            <Link to="/swatee-yogpeeth">
                                                <li className='px-4 py-2 hover:bg-gray-100 hover:text-primary border-b' onClick={() => setToggle(false)}>Swatee YogPeeth</li>
                                            </Link>
                                            <Link to="/swatee-ayur-jyoti">
                                                <li className='px-4 py-2 hover:bg-gray-100 hover:text-primary' onClick={() => setToggle(false)}>Swatee Ayur Jyoti</li>
                                            </Link>
                                        </ul>
                                    }
                                </li>
                            </Link>
                            <Link to="/gallery" onClick={() => setToggle(false)}>
                                <li className='p-3'>Gallery</li>
                            </Link>
                            <Link to="/contact" onClick={() => setToggle(false)}>
                                <li className='p-3'>Contact Us</li>
                            </Link>
                            <Link to="/offers" onClick={() => setToggle(false)}>
                                {/* <li className='px-4 py-2 my-2 rounded-lg bg-primary text-white sm:w-[150px] sm:m-auto'>Offers</li> */}
                                <li className='animate-charcter px-4 py-[5px]'>Offers</li>
                            </Link>
                            <li className='py-2'></li>
                            <Link to="/catalogue" onClick={() => setToggle(false)}>
                                <li className='px-4 py-2 mt-2 rounded-lg bg-primary text-white sm:w-[150px] sm:m-auto'>Catalogue</li>
                            </Link>
                        </ul>
                    </div>
                    {
                        toggle ?
                            <AiOutlineClose onClick={() => setToggle(!toggle)} className='text-3xl md:hidden block sm:text-xl' />
                            :
                            <AiOutlineMenu onClick={() => setToggle(!toggle)} className='text-3xl sm:text-xl xl:hidden 2xl:hidden block' />
                    }

                    {/* cart & login icons */}
                    {/* <div className='flex items-center'>

                        <div className='w-[50px] sm:w-[30px] sm:h-[30px] h-[50px] bg-[#fff] drop-shadow-xl flex justify-center items-center  mr-4'>
                            <BiUser className='text-xl  sm:text-sm' />
                        </div>
                        <Link to='/cart'>
                            <div className='w-[50px] sm:w-[30px] sm:h-[30px] h-[50px] bg-[#fff] drop-shadow-xl flex justify-center items-center mr-4'>
                                <AiOutlineShoppingCart className='text-xl  sm:text-sm' /><sup>2</sup>
                            </div>
                        </Link>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Header