import React from 'react'
import HomeCategories from '../products/HomeCategories'

const CategoryHome = () => {

    return (
        <div className='w-full bg-[#f9f9f9]'>
            <div className='max-w-[1200px] mx-auto px-4 py-12'>
                <h2 className='text-[36px]'>Products By Category</h2>
            </div>
            <div className='max-w-[1200px] mx-auto px-4 grid 2xl:grid-cols-4 xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 xl:grid-col-4 gap-6 pb-20'>
                <HomeCategories />
            </div>

        </div>
    )
}

export default CategoryHome
