import React from 'react'
import { Link } from 'react-router-dom';
// import logo from '../assets/logo.jpeg'
import aloevera from '../assets/aloe-vera.jpeg'
import TitleBar from '../component/title/TitleBar'
import ContactForm from '../component/forms/ContactForm';

const ReturnPolicy = () => {
    return (
        <>
            <TitleBar pagetitle="Return Policy" breadcrumbs={
                <>
                    <Link to="/">Home</Link> &gt;
                    <Link to=""> Return Policy</Link>
                </>}
            />

            {/* About swatee pvt. ltd */}
            <div className='container px-4 my-6 flex sm:flex-col lg:flex-row'>
                <div className='basis-[75%] py-6 px-8 mt-8 bg-[#f2f2f2]'>
                    {/* <img className='w-[40%]' src={logo} alt="/Swatee Logo" /> */}
                    <h2 className='py-6 text-lg'>उत्पाद वापसी नीति – </h2>
                    {/* <p className='text-justify text-sm p-2 text-[#4d4d4d]'>
                        किसी को आपसे शिकायत होना एक सामान्य प्रकिया प्रक्रिया हैं इसे अन्यथा ना लें, शिकायतकर्ता की शिकायत अनिवार्य रूप से सुनें और उसका समाधान या निवारण करने में अपना शतप्रतिशत सहयोग दें उसके लिए कंपनी द्वारा कुछ तरीके / नियम बताएँ हैं जो निम्न प्रकार से हैं –
                    </p> */}
                    <ol className="text-[#4d4d4d] p-4 list-decimal text-sm">

                        <li className='p-1'>
                            यदि डायरेक्ट सेलर या उपभोक्ता किसी प्रोडक्ट को वापिस करना चाहता हैं तो खरीद की तिथि से ३० दिन की अवधि में कभी भी कर सकता हैं.
                        </li>

                        <li className='p-1'>
                            उत्पाद वापसी आप अपनी upline या सीधे कंपनी के पिक-अप केंद्र से कर सकते हैं.
                        </li>

                        <li className='p-1'>
                            उत्पाद को एक्सपायरी डेट से कम से कम ३० दिन पुर्व तक ही वापस लिया जायेगा.
                        </li>

                        <li className='p-1'>
                            उत्पाद विक्रय किये जाने योग्य अवस्था में होना चाहिए अर्थात क्षतिग्रस्त ना हो.
                        </li>

                        <li className='p-1'>
                            उत्पाद वापसी फॉर्म पर पूरा विवरण भरा होना चाहिए.
                        </li>

                        <li className='p-1'>
                            उत्पाद फार्म वापसी का प्रारूप यहाँ पर दिया जा रहा हैं
                        </li>

                    </ol >

                </div>
                <div className='basis-[25%] h-[450px] ml-4 mt-8 bg-[#f2f2f2] '>
                    <h2 className='py-2 sm:text-3xl text-lg text-white text-center bg-primary'>
                        New Arrivals
                    </h2>

                    <img className='sm:max-w-[90%] mx-auto' src={aloevera} alt="/product-image" />
                </div>
            </div>


            {/* Contact Form  */}
            <div className='container px-4 py-12 flex items-center sm:flex-col lg:flex-row '>
                <div className='basis-[100%] shadow-md p-12 m-[10px]'>
                    <h3 className='py-2 border-l-2 border-primary mb-8'>
                        <span className='pl-2'>Product Return Form</span>
                    </h3>
                    <h3 className='py-6 text-lg'>Provide below details to process return</h3>

                    <div className='basis-[100%]'>

                        <ContactForm />

                    </div>
                </div>
            </div>

        </>
    )
}

export default ReturnPolicy