import React from 'react'
import CatPdf from '../assets/pdf/Swatee-Health-Family-Catalogue.pdf'

const Catalogue = () => {
    return (
        <div className='max-w-[1200px] mx-auto px-4 py-12' >
            <a
                href={CatPdf}
                target="_blank"
                rel="noopener noreferrer"
            >
                <button type="submit" className="text-white text-xl bg-primary px-12 py-4">Click Here To View Our Catalogue </button>
            </a>
            <br /><br />
            <a
                href={CatPdf}
                download="Swatee_Health_Family_Catalogue.pdf"
            >
                <button type="submit" className="text-white text-xl bg-primary px-12 py-4">Click Here To Download Our Catalogue </button>
            </a>
        </div>
    )
}

export default Catalogue
