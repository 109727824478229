import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { AiOutlineShoppingCart } from 'react-icons/ai'

import TitleBar from '../title/TitleBar'
import aloevera from '../../assets/aloe-vera.jpeg'
import payment from '../../assets/payment.png'
import FeaturedProduct from '../home/FeaturedProduct'
import CategoryHome from '../home/CategoryHome'
import LoginForms from '../home/LoginForms'
import ProductQuantity from '../products/ProductQuantity';
import { useDispatch, useSelector } from 'react-redux';
import { cartDataAdd } from '../../actions';
import { services } from '../../services';
// Slider imports 
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../productslider/ProductSlider.css";
// slider end

const ProductsDetails = (productId) => {

  const [currentSlide, setCurrentSlide] = useState(0);
  const [baseurl, setBaseUrl] = useState('');
  const [images, setImages] = useState([]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setCurrentSlide(current),
  };


  const { id } = useParams();

  const [singleProduct, setSingleProduct] = useState([]);
  const [quantityForAdd, setQuantityForAdd] = useState(1);

  const [tag, setTag] = useState([]);

  const data = useSelector((state) => state.cartReducer);
  const dispatch = useDispatch();

  const udpateThePrice = (itemQuantity, itemId) => {
    setQuantityForAdd(itemQuantity);
  };

  // Slider Image And tags
  useEffect(() => {
    let imagesForShow = [];
    let tagsForShow = [];
    singleProduct?.more_product_images?.map((item) => {
      imagesForShow.push(`${baseurl}${item.image_name}`);
    });

    singleProduct?.tags?.map((item) => {
      tagsForShow.push(`${item?.tag_data?.tag_name}`);
    });
    console.log(tagsForShow, '>>>>>>>>>>>>>>>.');
    setTag(tagsForShow)
    setImages(imagesForShow);
  }, [singleProduct])

  // Add to Cart api
  const handleAddToCart = (product, quantity) => {
    let previousProductInState = data.cart;
    console.log(previousProductInState);
    let alredyExist = previousProductInState.filter((item) => {
      if (product.id === item.id) {
        return product;
      }
    });
    if (alredyExist.length > 0) {
      swal({
        TitleBar: "Oops!",
        text: "Product already Available in cart",
        icon: "warning",
      });
    } else {
      let updatedProductList = { ...product, newQuantityAdded: quantity };
      dispatch(cartDataAdd(updatedProductList));
      swal({
        TitleBar: "Success!!",
        text: "Product Added To Cart Successfully",
        icon: "success",
      });
    }
  };

  const fetchProduct = async (id) => {
    try {
      const response = await axios.get(`${services.getProduct}/${id}`);
      setSingleProduct(response?.data.data[0]);
      setBaseUrl(response.data.base_url);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    ///let productIdMain = window.location.href.split('?')[1];
    fetchProduct(id);
  }, [id]);

  // Remove HTML TAGS from description
  const removeHTMLTags = (htmlString) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || '';
  };

  const description = removeHTMLTags(singleProduct.product_description);
  const shortDescription = removeHTMLTags(singleProduct.product_short_description);

  if (!singleProduct) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <TitleBar
        pagetitle={singleProduct?.product_name}
        breadcrumbs={
          <>
            <Link to="/">Home</Link> &gt;
            <Link to=""> Product Details</Link>
          </>
        }
      />

      <div className="w-full">
        {/* slider */}
        <div className="container px-4 py-28 flex  sm:py-8 sm:flex-col lg:flex-row">
          <div className="basis-[35%]">
            <div className="container-slider mx-auto">
              <Slider {...settings}>
                {images.map((image, index) => (
                  <div key={index}>
                    <img src={image} alt={`Product ${index + 1}`} />
                  </div>
                ))}
              </Slider>
              <div className="flex mt-4">
                {images.map((image, index) => (
                  <div
                    key={index}
                    className={`w-16 h-16 mx-2 border border-gray-300 ${currentSlide === index ? "border-indigo-500" : ""
                      }`}
                    onClick={() => setCurrentSlide(index)}
                  >
                    <img src={image} alt={`Product ${index + 1}`} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="basis-[40%] px-[30px]">
            {/* Product TitleBar, Description & Price Section */}
            <div className="sm:pt-6">
              <h3 className="text-[1.4rem]">{singleProduct?.product_name}</h3>
              <p className="text-primary text-[2rem] inline-block font-bold">
                ₹{" "}
                {singleProduct?.product_sale_price
                  ? singleProduct?.product_sale_price
                  : singleProduct?.product_regular_price}
              </p>
              {/* <p className="text-primary opacity-60 line-through text-xl inline-block font-bold ml-[30px]">
                ₹ 300
              </p> */}
              <p className="py-4 text-sm">
                {shortDescription}
              </p>
            </div>

            {/* Product stock Section */}
            <div className="mb-6">
              <p className="text-sm font-bold"> Stock: {singleProduct?.product_quantity}</p>
            </div>
            {/* Add to Cart Section */}
            <div className="flex items-center sm:items-start sm:flex-col lg:flex-row">
              <ProductQuantity
                item={{ singleProduct }}
                udpateThePrice={udpateThePrice}
              />
              <div className="px-6 sm:px-0">
                {data.cart.filter((item) => item.id === singleProduct.id).length >
                  0 && (
                    <a
                      className=" px-5 py-2.5 relative  overflow-hidden font-medium  text-white text-center block border border-primary cursor-pointer" style={{ background: "gray" }}
                      // onClick={addToCart}
                      onClick={() => {
                        handleAddToCart(singleProduct, quantityForAdd);
                      }}
                    >
                      <span className="absolute top-0 left-0 flex w-[0] h-full mb-0 transition-all duration-700 ease-out transform translate-y-0 bg-white group-hover:w-full "></span>
                      <span className="relative group-hover:text-primary">
                        <AiOutlineShoppingCart className="inline mr-2" /> Add To
                        Cart
                      </span>
                    </a>
                  )}
                {!data.cart.filter((item) => item.id === singleProduct.id).length >
                  0 && (
                    <a
                      className=" px-5 py-2.5 relative group overflow-hidden font-medium bg-primary text-white text-center block border border-primary cursor-pointer"
                      // onClick={addToCart}
                      onClick={() => {
                        handleAddToCart(singleProduct, quantityForAdd);
                      }}
                    >
                      <span className="absolute top-0 left-0 flex w-[0] h-full mb-0 transition-all duration-700 ease-out transform translate-y-0 bg-white group-hover:w-full "></span>
                      <span className="relative group-hover:text-primary">
                        <AiOutlineShoppingCart className="inline mr-2" /> Add To
                        Cart
                      </span>
                    </a>
                  )}
              </div>
            </div>

            {/* Product Category Section */}
            <div className="mt-6">
              <p className="text-sm">
                {" "}
                Categories:{" "}
                <Link
                  to={`${services.getCategory}/${singleProduct?.category?.id}`}

                ><span className="font-bold">
                    {singleProduct?.category?.category_name}
                  </span>{" "}
                </Link>
              </p>
            </div>
            {/* Product tags Section */}
            <div className="mt-6">
              <p className="text-sm ">
                {" "}
                Tags:{" "}

                {tag?.map((item) => {
                  return (<span className="font-bold">
                    {item}, </span>)
                })}

              </p>
            </div>

            {/* Payment Image Section */}
            <div className='py-8'>
              <h4 className='pb-4 font-normal'>
                Guaranteed Safe Checkout
              </h4>
              <img src={payment} alt="/safe-payment" />
            </div>
          </div>
          {/* extra image area */}
          <div className="basis-[20%] ml-[5%]">
            <h3 className="text-[1.4rem] text-center bg-primary text-white p-2">
              New Arrivals
            </h3>
            <img
              className="sm:max-w-[90%] mx-auto"
              src={aloevera}
              alt="/product-image"
            />
          </div>
        </div>

        {/* Description Area */}
        <div className="container bg-[#f2f2f2] px-4 py-8 flex mb-12 sm:flex-col lg:flex-row">
          <div className="basis-[100%] px-[30px]">
            <h3 className="text-[1.4rem]">Description</h3>
            <hr />
            <p className="py-2 leading-8">
              {description}
            </p>
          </div>
        </div>
      </div>

      <FeaturedProduct />
      <CategoryHome />
      <LoginForms />
    </div>
  );
}

export default ProductsDetails