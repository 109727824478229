import React from "react";
import bg from '../../assets/bg.png'

function TitleBar(props) {
    return (
        <div className="flex px-4 py-20 sm:py-4 shadow-md"
            style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover" }}
        >
            <div className="container px-4">
                <h1 className="text-3xl text-[#fff] font-semibold py-4 sm:text-[16px] sm:py-0">{props.pagetitle}</h1>
                <p className="text-sm text-[#fff] sm:text-[12px]">{props.breadcrumbs}</p>
            </div>

        </div>
    );
}

export default TitleBar;
