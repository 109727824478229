import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineShoppingCart, AiOutlineSearch, AiOutlineHeart } from 'react-icons/ai';
import axios from 'axios';
import { services } from '../../services';
import { useSelector } from 'react-redux';
import Profile from './Profile';

const TopBar = () => {
    const [userData, setUserData] = useState({});
    const isLoggedIn = localStorage.getItem("token");
    let navigate = useNavigate();
    const data = useSelector((state) => state.cartReducer);

    useEffect(() => {
        if (isLoggedIn) {
            axios.get(services.getUsers).then(res => {
                // console.log(res.data.data);
                setUserData(res?.data?.data);
            }).catch(e => {
                console.log(e);
            });
        }
    }, [isLoggedIn]);


    return (
        <div className='bg-primary py-2'>
            <div className="container flex justify-between font-semibold px-4 sm:flex-wrap sm:text-center">
                <div className='basis-1/3 sm:basis-full'><p className="text-[white] text-sm">Call us : <a className='underline hover:text-[#f5f5f5]' href="tel:+91 70555 20015">+91 7055520015</a> From 10 AM to 06 PM</p> </div>
                <div className='basis-1/3 sm:basis-full'>
                    <p className='text-white text-sm text-center'>
                        Welcome &nbsp;
                        {isLoggedIn ? (
                            <Link to="/myaccount">
                                <span className='cursor-pointer'>
                                    {userData[0]?.first_name + ' ' + userData[0]?.last_name}
                                </span>
                            </Link>
                        ) : (
                            <span>To Swatee Health Solutions</span>
                        )}
                    </p>
                </div>
                <div className='basis-1/3 sm:basis-full'>

                    <ul className="text-[white] flex justify-end text-sm sm:py-2 sm:justify-center">

                        {isLoggedIn ? (
                            <li className='px-4 underline cursor-pointer'
                                onClick={() => {
                                    localStorage.removeItem("token");
                                    navigate("/login")
                                }}
                            >
                                Logout
                            </li>
                        ) : (
                            <Link to="/login">
                                <li className='px-4 underline'>Login/Register</li>
                            </Link>
                        )}

                        {/* My Account menu link */}

                        {isLoggedIn && (
                            <Link to="/myaccount">
                                {/* <li className='px-4 underline'>{userData[0]?.first_name}</li> */}
                                <li className='px-4 underline'>My Account</li>
                            </Link>
                        )}

                        {isLoggedIn && (
                            <Link to="https://admin.swatee.in/seller-registration" target="_blank">
                                <li className='px-4 underline'>Join as DS</li>
                            </Link>
                        )}

                        {/* < Link to="/register" >
                            <li className='px-4 underline'>
                                <AiOutlineSearch className='text-xl' />
                            </li>
                        </Link> */}
                        {/* < Link to="/whishlist" >
                            <li className='px-4 underline'>
                                <AiOutlineHeart className='text-xl' />
                            </li>
                        </Link> */}
                        < Link to="/cart" >
                            <li className='px-4'>
                                <span className='text-sm mr-[4px]'>Cart</span><AiOutlineShoppingCart className='text-xl inline' /><sup>{data?.cart.length}</sup>
                            </li>
                        </Link>
                    </ul>
                </div>
            </div>
        </div >
    )
}

export default TopBar
