import React from 'react'
import { Link } from 'react-router-dom'
import LoginApi from '../login/LoginApi'
import SellerForm from '../forms/SellerForm'

const LoginForms = () => {
    return (
        <div>

            {/* JOIN HANDS WITH NATURE section */}
            <div className='max-w-[500px] sm:max-w-[300px] top-[30px] rounded-3xl relative left-[33%] sm:left-[14%]'>
                <div className='bg-primary px-6 rounded-3xl py-4 sm:px-0'>
                    <h3 className='text-white text-center sm:text-[18px]'>
                        JOIN HANDS WITH NATURE
                    </h3>
                </div>
            </div>

            <div className='mx-4'>
                <div className='container px-12 sm:px-4 flex sm:flex-col lg:flex-row border-2 rounded-3xl border-primary mb-20'>
                    <div className='basis-[40%] py-8 px-6 mt-12'>
                        <h3 className='pb-8 text-[24px]'>
                            Log In To Your Account
                        </h3>
                        <LoginApi />
                        <Link to='/forgot'><p className='pt-4 hover:text-primary'>FORGOTTEN YOUR PASSWORD?</p></Link>
                    </div>
                    <div className='basis-[60%] py-8 px-6 ml-[10%] sm:ml-0 mt-12'>
                        <h3 className='pb-8 text-[24px]'>
                            I Want To Be A Direct Seller
                        </h3>

                        {/* <form onSubmit={handleSubmit}> */}
                        <SellerForm />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LoginForms