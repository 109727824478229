import React, { useState } from 'react';
import { PlusIcon, MinusIcon } from '@heroicons/react/solid';

function ProductQuantity(props) {
    const { item, udpateThePrice } = props;
    const [quantity, setQuantity] = useState((item.newQuantityAdded ? item.newQuantityAdded : 1));

    const handleIncrement = () => {
        setQuantity(quantity + 1);
        udpateThePrice(quantity + 1, item);
    };

    const handleDecrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
            udpateThePrice(quantity - 1, item);
        }
    };

    return (
        <div className="flex items-center justify-center">
            <button
                type="button"
                className="bg-gray-200 rounded-full w-8 h-8 flex items-center justify-center text-gray-600 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                onClick={handleDecrement}
            >
                <MinusIcon className="h-5 w-5" />
            </button>
            <span className="mx-2 text-gray-700">{quantity}</span>
            <button
                type="button"
                className="bg-gray-200 rounded-full w-8 h-8 flex items-center justify-center text-gray-600 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                onClick={handleIncrement}
            >
                <PlusIcon className="h-5 w-5" />
            </button>
        </div>
    );
}

export default ProductQuantity;
