import React from 'react'
import { Link } from 'react-router-dom';
import TitleBar from '../component/title/TitleBar'
import FeatuteImage from '../assets/blog/helthy-liver.webp'
const HealthyLiver = () => {
    return (
        <>
            <TitleBar pagetitle="Ways to keep liver healthy" breadcrumbs={
                <>
                    <Link to="/">Home</Link> &gt;
                    <Link to="/">Blogs</Link> &gt;
                    <Link to=""> Ways to keep liver healthy</Link>
                </>}
            />

            {/* About swatee pvt. ltd */}
            <div className='container px-4 my-12 flex sm:flex-col lg:flex-row'>
                <div className='basis-[70%] px-8'>
                    <img src={FeatuteImage} alt="/Swatee Logo" />
                    <h1 className='pt-6 text-[30px] py-8 text-primary'>
                        Ways to keep liver healthy
                    </h1>
                    <p className='text-justify pb-6'>
                        लिवर या फिर जिगर मानव शरीर की सबसे महत्वपूर्ण ग्रंथियों में से एक है। स्वस्थ जीवन जीने के लिए लिवर का स्वस्थ होना अनिवार्य है। लिवर आपके शरीर में अनेक महत्वपूर्ण गतिविधियों को नियंत्रित करता है और शरीर के सुचारू रूप से कार्य करने में सहायता करता है। यह खाने को पचाने में अथवा उसके उपापचय में एक अभिन्न भूमिका निभाता है। इसके अलावा यह हमारे इम्यून को भी बढ़ावा देता है और पोषक तत्वों के संचयन में भी सहायक है। यह बहुत सारे रासायनिक प्रदार्थों का उत्पादन करता है जो अन्य अंगों के कार्यशीलता के लिए अनिवार्य हैं। यह रक्त की रचना को भी निर्धारित करता है और रक्त से विषाक्त पदार्थों का निकास कर उसे स्वस्थ बनाता है। यह और भी कई महत्वपूर्ण कार्यों को अंजाम देता है।
                    </p>
                    <p className='text-justify pb-6'>
                        चूँकि लीवर हमारे शरीर में बहुत सारे जरुरी कार्यों को पूरा करता है, स्वस्थ शरीर के लिए लीवर का स्वस्थ होना भी बहुत जरुरी है। और यकीन मानिये लीवर को स्वस्थ रखना बहुत ही आसान है। लीवर को स्वस्थ रखने के लिए आपको बस एक स्वस्थ जीवनशैली को अपनाना है और अपने दैनिक आहार में लीवर के लिए स्वास्थ्यवर्धक फल, सब्जी एवं मसालों को शामिल करना है।
                    </p>
                    <p>लिवर को स्वास्थ्य रखने के लिए इन बातों का रखें ध्यान –&nbsp;</p>

                    <ul className='text-[#5C727D] text-sm list-disc mt-2 p-4'>
                        <li>सिगरेट एवं शराब का सेवन ना करें।</li>
                        <li>दिन में कम से कम 6 घंटे अवश्य सोएं।</li>
                        <li>देर से सोने और देर से उठने जैसी आदतों से दूर रहें।</li>
                    </ul>

                    <h1 className='pt-6 text-[30px] py-8 text-primary'>
                        लीवर को साफ और स्वस्थ रखने के लिए आहार – Diet to keep liver healthy in Hindi
                    </h1>

                    <h1 className='pt-6 text-[30px] py-8 text-[#5C727D]'>
                        लिवर को स्वस्थ रखें लहसुन से – Garlic for liver cleanse in Hindi
                    </h1>
                    <p className='text-justify pb-6'>लहसुन आपके लिवर को स्वच्छ रखने में अत्यंत सक्षम है। यह लिवर में उपस्थित उन एन्ज़ाइम्स को सक्रिय कर देता है जो लिवर सो साफ करने में सहायक हैं। इसमें एल्लीसिन और सेलेनियम नाम के दो तत्व हैं जो लिवर को साफ तो करते ही हैं, परन्तु साथ ही में लिवर को क्षति पहुँचने से भी बचाते हैं।&nbsp;कोलेस्ट्रॉल के उच्च स्तर से लिवर की कार्यशीलता प्रभावित हो सकती है और लहसुन कॉलेस्ट्राल के स्वस्थ स्तर को नियंत्रित कर लिवर के कामकाज को प्रभावित होने से रोकता है।&nbsp;</p>
                    <p>लिवर के स्वास्थ्य को बनाए रखने के लिए&nbsp;–</p>
                    <ul className='text-[#5C727D] text-sm list-disc mt-2 p-4'>
                        <li>संसाधित लहसुन (processed garlic) की जगह कच्चे लहसुन का सेवन करें।&nbsp;(और पढ़ें –&nbsp;<a href="https://www.myupchar.com/tips/lahsun-ke-fayde-aur-nuksan-in-hindi">लहसुन के फायदे</a>)</li>
                        <li>2-3 लहसुन की कलियों का सेवन रोजाना करें और जब भी मुमकिन हो, लहसुन को अपने खाने में शामिल करें।</li>
                        <li>डॉक्टर से सलाह लेने के बाद, आप लहसुन के अनुपूरक (Garlic Supplements) का प्रयोग भी कर सकते हैं।</li>
                    </ul>
                    <h1 className='pt-6 text-[30px] py-8 text-[#5C727D]'>
                        लीवर को साफ करने के लिए चुकंदर है प्रभावी – Beetroot for liver cleansing in Hindi
                    </h1>
                    <p className='text-justify pb-6'>चुकंदर भी अपने प्रभावी गुणों की वजह से लिवर को साफ़ करने के लिए सर्वोत्तम आहार की लिस्ट में शामिल है। इसमें निहित बीटा-कैरोटीन लिवर को उत्तेजित करता है और उसके समग्र कार्यशीलता में सुधार लाता है। इसके अलावा यह प्राकृतिक रक्त शोधक भी है।</p>

                    <ul className='text-[#5C727D] text-sm list-disc mt-2 p-4'>
                        <li>अपने दैनिक आहार में चुकंदर या फिर इसके रस को शामिल करें।</li>
                        <li>आप चुकंदर का इस्तेमाल करके एक प्रभावशाली सलाद भी बना सकते हैं। एक कप कटे हुए चुकंदर में दो चम्मच जैतून का तेल और आधा चम्मच नींबू&nbsp;का रस मिलाएं। सारी सामग्री को अच्छे से मिक्स करें और हर दो घंटे में रोज़ाना दो चम्मच खाएं।</li>
                    </ul>

                    <h1 className='pt-6 text-[30px] py-8 text-[#5C727D]'>
                        लीवर को स्वस्थ रखता है नींबू – Lemon for liver detoxification in Hindi
                    </h1>
                    <p className='text-justify pb-6'>नींबू भी लिवर को साफ़ करने में मदद करता है जिसका श्रेय इसमें निहित डी-लिमोनेने नामक एक तत्व को जाता है। यह लिवर की कोशिकाओं को सक्रिय कर देता है जो लिवर को डीटोक्सीफाय करने में मदद करते हैं। इसके अतिरिक्त इसमें विटामिन सी भी उच्च मात्रा में निहित होता है जो लिवर को पाचन क्रिया का समर्थन देने के लिए एन्ज़ाइम्स का उत्पादन करने में मदद करता है। यह लिवर द्वारा खनिज के अवशोषण को भी बढ़ावा देता है।</p>
                    <p>तो जल्दी से निम्बू पानी बनाये, इसमें शहद के रूप में मिठास घोलें और नियमित अंतराल पर इसका सेवन करें।</p>

                    <h1 className='pt-6 text-[30px] py-8 text-[#5C727D]'>
                        लिवर को डिटॉक्स करती है ग्रीन टी – Green tea for liver detox in Hindi
                    </h1>
                    <p>आप रोज़ाना ग्रीन टी पीकर शरीर में एकत्रित हुए वसा एवं विषाक्त पदार्थों को निकाल सकते हैं और साथ ही में शरीर को हाइड्रेट भी कर सकते हैं।</p>
                    <p className='text-justify pb-6'>इंटरनेशनल जर्नल ऑफ ओबीसिटी में प्रकाशित एक 2002 के अध्ययन में पाया गया कि ग्रीन टी में निहित कैटेचिन्स लिवर में लिपिड अपच को प्रोत्साहित करते हैं। यह लिवर में वसा संचय पर रोक लगाने में सहायक हैं। यह शराब की तरह विषाक्त तरल पदार्थों के हानिकारक प्रभावों से भी लिवर की रक्षा करते हैं। ग्रीन टी लिवर के विकारों को रोकने में भी फायदेमंद हैं। कैंसर कॉज़िस एंड कंट्रोल में प्रकाशित एक 2009 के अध्ययन के अनुसार, जो लोग ग्रीन टी का सेवन नियमित रूप से करते हैं, उनमें लीवर कैंसर के विकसित होने का खतरा कम होता है। </p>
                    <p>दिन में रोजाना दो से तीन कप ग्रीन टी अवश्य पियें।</p>

                    <p><strong>नोट –</strong>&nbsp;अधिक ग्रीन टी के सेवन से बचें क्योंकि इससे आपके लिवर एवं अन्य अंगों पर बुरा प्रभाव पड़ सकता है।</p>

                    <h1 className='pt-6 text-[30px] py-8 text-[#5C727D]'>
                        स्वस्थ लिवर के लिए अच्छी है हल्दी – Turmeric for healthy liver in Hindi
                    </h1>
                    <p className='text-justify pb-6'>हल्दी भी लिवर को स्वच्छ एवं स्वस्थ रखने के लिए सर्वोत्तम आहार में से एक है। यह शरीर की वसा को पचाने की क्षमता में सुधार लाती है और लिवर को डीटोक्सीफाय करने में मदद करती है। यह लिवर के क्षतिग्रस्त कोशिकाओं को पुनर्जीवित करने में भी सहायता करती है। </p>
                    <p>लिवर को स्वस्थ एवं डीटोक्सीफाय करने के लिए –</p>

                    <ul className='text-[#5C727D] text-sm list-disc mt-2 p-4'>
                        <li>¼ चम्मच हल्दी पाउडर एक गिलास पानी में मिलाएं और इसे उबाल लें। प्राप्त हल्दी-पानी को कुछ हफ़्ते के लिए दिन में दो बार पियें।</li>
                        <li>इसके अलावा, अपने दैनिक खाना पकाने की प्रक्रिया में भी इस मसाले को शामिल कर सकते हैं।</li>
                    </ul>

                    <h1 className='pt-6 text-[30px] py-8 text-[#5C727D]'>
                        लीवर की देखभाल के लिए खाएँ सेब – Apple for liver health in Hindi
                    </h1>
                    <p className='text-justify pb-6'>“एक दिन में एक सेब खाएं और लिवर के विकारों को दूर भगाएं।” सेब पेक्टिन का एक समृद्ध स्रोत है जो पाचन प्रणाली एवं कोलेस्ट्रॉल से विषाक्त पदार्थों की निकाल कर, लिवर को ज़्यादा काम करने से बचाता है। इसके अलावा, सेब में मैलिक एसिड भी निहित है जो खून से कार्सिनोजन और अन्य विषाक्त पदार्थों को दूर करने में सहायक है। </p>
                    <p>रोजाना एक सेब खाएं या फिर उसके जूस का सेवन करें। </p>

                    <h1 className='pt-6 text-[30px] py-8 text-[#5C727D]'>
                        लिवर को स्वस्थ रखने के उपाय हैं अखरोट – Walnuts for liver health in Hindi
                    </h1>
                    <p className='text-justify pb-6'>अखरोट में उच्च मात्रा में निहित एमिनो एसिड लिवर को विषाक्त प्रदार्थों से छुटकारा दिलाने में सहायता करता है। इसके अतिरिक्त अख़रोट में ग्लूटाथिओन और ओमेगा-3 फैटी एसिड भी निहित हैं जो प्राकृतिक लिवर को साफ करने की प्रक्रिया को बढ़ावा देते हैं।</p>
                    <p className='text-justify pb-6'>कृषि और खाद्य रसायन विज्ञान के जर्नल में प्रकाशित एक 2008 के अध्ययन के अनुसार, अखरोट में समाविष्ट पोलीफेनॉल्स, कार्बन टेट्राक्लोराइड और डी-ग्लाक्टोसेमिने से होने वाली लिवर की क्षति को रोकने में सहायक हैं।</p>
                    <p>रोजाना एक मुट्ठी अखरोट चबा कर खाएं। आप अखरोट को सलाद एवं सूप पर भी डालकर उसका सेवन कर सकते हैं।</p>

                    <h1 className='pt-6 text-[30px] py-8 text-[#5C727D]'>
                        लीवर को स्ट्रांग करें ब्रोकोली से – Broccoli health benefits for liver in Hindi
                    </h1>
                    <p className='text-justify pb-6'>लिवर को साफ करने के लिए ब्रोकोली को अपने दैनिक आहार में शामिल करें। उच्च मात्रा में ग्लूकोसाइनोलेट्स निहित होने की वजह से, यह कार्सिनोजन और अन्य हानिकारक विषाक्त पदार्थों को निकास द्वार दिखाने में मदद करता है। इसमें फाइबर और घुलनशील विटामिन ई भी हैं जो लिवर की कार्यशीलता में सुधार लाते हैं।</p>
                    <p>हर सप्ताह तीन बार एक कप ब्रोकोली का सेवन करें और लिवर के स्वास्थ्य को बढ़ावा दें।</p>

                    <h1 className='pt-6 text-[30px] py-8 text-[#5C727D]'>
                        लिवर की सफाई के लिए करें चकोतरे का सेवन – Grapefruit for liver in Hindi
                    </h1>
                    <p className='text-justify pb-6'>विटामिन सी, पेक्टिन और एंटी-ऑक्सीडेंट का एक समृद्ध स्रोत होने की वजह से, चकोतरा लिवर को साफ़ रखने में बहुत प्रभावशाली है और लिवर की प्राकृतिक की प्रक्रिया को बढ़ावा देता है। इसमें ग्लूटाथिओन, जो की एक प्रभावी एंटी-ऑक्सीडेंट है, भी निहित है। यह लिवर को फ्री-रेडिकल क्षति से बचाता है और भारी धातुओं को डीटोक्सीफाय करने में सहायक है।</p>
                    <p>लिवर को डेटोक्सीफाय करने के लिए एक गिलास ताज़ा चकोतरे का रस पियें या फिर इसके फल का सेवन करें।</p>
                    <p><strong>नोट –</strong>&nbsp;यदि आप किसी भी दवाई का सेवन कर रहे हैं तो चकोतरा के सेवन से पहले डॉक्टर की सलाह अवश्य लें।</p>

                    <h1 className='pt-6 text-[30px] py-8 text-[#5C727D]'>
                        लीवर को मजबूत करें एवोकाडो से – Avocado for liver health in Hindi
                    </h1>
                    <p className='text-justify pb-6'>अमेरिकन केमिकल सोसाइटी में हुए एक अध्य्यन में पाया गया कि एवोकाडो में कुछ ऐसे केमिकल निहित हैं जो लिवर को क्षति से बचाते हैं। इसमें ग्लूटाथिओन नामक तत्व भी बहुत ही उच्च मात्रा में निहित है जो लिवर को डीटोक्सीफाय करता है और उसकी कार्यशीलता में सुधार लाता है। एवोकाडो में समाविष्ट अघुलनशील वसा अच्छे कोलेस्ट्रॉल को बढ़ाने में सहायता करता है और बुरे कोलेस्ट्रॉल को कम करता है। और क्योंकि कोलेस्ट्रॉल का स्तर लिवर को प्रभावित करता है, एवोकाडो का सेवन करना लिवर के लिए फायदेमंद है। इसके अलावा इसमें अनेक विटामिन, न्यूट्रिएंट एवं खनिज भी निहित हैं जो लिवर के लिए स्वास्थ्यवर्धक हैं।</p>
                    <p className='pb-6'>लिवर को पहुँचने वाली क्षति को उलटे पाँव लौटाने के लिए प्रति सप्ताह एक या दो एवोकाडो का सेवन करें।</p>
                    <p className='text-justify pb-6'>और सबसे आवश्यक बात – हमेशा खुश रहें, क्योंकि आपके तनाव का असर लिवर की कार्यशीलता पर भी पड़ता है और वो ढंग से अपने कार्यों का निर्वाह नहीं कर पाता है। इसलिए खुश रहिये और ऊपरलिखित आहारों को अपने दैनिक आहार में शामिल कीजिये। स्वस्थ आहार का सेवन करने के साथ अपने लिवर को स्वस्थ रखने के लिए रोज़ाना व्यायाम भी करें।</p>


                    {/* <ol className='text-[#5C727D] text-sm list-decimal mt-2 p-4 '>
                    <li className='p-1'>This company, SWATEE Health Solutions Private Limited, is a project of the SWATEE family.</li>
                    <li className='p-1'>The company's corporate office is located at H39, Shivalik Nagar, Haridwar, Uttarakhand, with the PIN code 249403.</li>
                    <li>The main branch office is situated near Gali No. 13 and 14, Chiddarwala, Punjab National Bank, on the main road between Rishikesh and Dehradun.</li>
                    <li className='p-1'>The director of the company is Miss Kusum Lata, and her permanent address is Nepali Farm, Rishikesh, Uttarakhand</li>
                    <li className='p-1'> The company is registered under the Company Act with registration number U74999UR2019OPC009795.</li>
                    <li className='p-1'>The PAN number of the company is ABCCS0684A, and the GSTIN is 05ABCCS0684A1ZS. </li>
                    <li className='p-1'>The company also holds an FSSAI License Number 12623005000005. </li>
                    <li className='p-1'>The company is also known by the abbreviation SHS. </li>
                </ol> */}
                </div>
                <div className='basis-[30%]  ml-4 bg-[#f2f2f2] '>
                    <h2 className='py-2 sm:text-3xl text-lg text-white text-center bg-primary'>
                        IMPORATANT LINKS
                    </h2>
                    <ul className="text-primary px-8 py-8 list-disc">

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/terms-policies" >Swatee Terms and Policies</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/complaints-and-redressals" >Complaints and Redressals</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/" >Advisory Committee</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/login" >Admin Login</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/return-policy" >Return Policy</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/shipping-policy" >Shipping Policy</Link>
                        </li>
                    </ul >
                    <h2 className='mt-12 py-2 sm:text-3xl text-lg text-white text-center bg-primary'>
                        SWATEE GROUP
                    </h2>
                    <ul className="text-primary px-8 py-8 list-disc">

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/swatee-jyotish-peeth" >Swatee Jyotish Peeth</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/" >Swatee Health Solutions PVT. LTD</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/swatee-yogpeeth" >Swatee YogPeeth</Link>
                        </li>

                        <li className='p-1'>
                            <Link className=' hover:text-secondary' to="/swatee-ayur-jyoti" >Swatee Ayur Jyoti</Link>
                        </li>
                    </ul >
                </div>
            </div>
        </ >
    )
}

export default HealthyLiver
