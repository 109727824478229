import React from "react";
import { Link } from "react-router-dom";
import LoginApi from "./LoginApi";
import TitleBar from "../title/TitleBar";

const Login = () => {
  return (
    <div className="w-full">
      <TitleBar
        pagetitle="Login Swatee Health Solution Pvt. Ltd."
        breadcrumbs={
          <>
            <Link to="/">Home</Link> &gt;
            <Link to=""> Login</Link>
          </>
        }
      />
      {/* <div className='max-w-[1200px] mx-auto px-4 py-12'>
                <h2 className='text-center'>Sign In <br />To Your Account</h2>
            </div> */}

      <div className="max-w-[1200px] flex mx-auto px-4 py-12 sm:flex-col">
        <div className="basis-[50%] mb-8 px-8">
          <h2 className="text-left py-8">
            Sign in <br />
            to your account
          </h2>
          <LoginApi />

          <Link to="https://admin.swatee.in/login">
            <button className="text-white text-xl bg-primary px-12 py-4 mt-4">
              DS Login
            </button>
          </Link>
          <a href="http://admin.swatee.in/password/reset" target="_blank">
            <p className="pt-4 hover:text-primary">Forgot your password?</p>
          </a>
        </div>
        <div className="basis-[50%] px-8 mb-8">
          <h2 className="text-left py-8">Don't have an account?</h2>
          <p className="mb-6">
            Add items to your wishlist / Get personalised recommendations /
            Check out more quickly / Track your orders / Sign up.
          </p>

          <Link to="/register">
            <button className="text-white text-xl bg-primary px-12 py-4">
              Create Account{" "}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
