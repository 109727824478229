import React, { useState, useEffect } from 'react'

import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs'
import { RxDotFilled } from 'react-icons/rx'
import slideone from '../../assets/slide-1.jpg'
import slidetwo from '../../assets/slide-2.jpg'

const Slider = () => {

    const slides = [
        {
            url: slideone
        },
        {
            url: slidetwo
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0)

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    }


    const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    }

    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex)
    }

    // Loop animation for images 
    useEffect(() => {
        // Set an interval to auto play the slides
        const intervalId = setInterval(() => {
            nextSlide()
        }, 3000) // Change the duration as per your need

        return () => {
            clearInterval(intervalId) // Clear the interval on unmounting
        }
    }, [currentIndex])

    return (
        <div className='max-w-[100%] h-[450px] w-full m-auto sm:max-w-[100%] sm:h-[120px]'>
            <div style={{ backgroundImage: `url(${slides[currentIndex].url})` }} className='w-full h-full bg-center bg-cover duration-500'></div>

            {/* Left Arrow */}
            <div className='absolute top-[45%] xl:top-[55%] sm:top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl  p-2  bg-black/20 text-white cursor-pointer sm:hidden'>
                <BsChevronCompactLeft onClick={prevSlide} size={20} />
            </div>

            {/* Right Arrow */}
            <div className='absolute top-[45%] xl:top-[55%] sm:top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl  p-2 bg-black/20 text-white cursor-pointer sm:hidden'>
                <BsChevronCompactRight onClick={nextSlide} size={20} />
            </div>

            <div className='absolute sm:hidden bottom-[30%] xl:bottom-[15%] sm:bottom-[60%] left-[48%] sm:left-[42%] flex justify-center '>
                {slides.map((slide, slideIndex) => (
                    <div key={slideIndex} onClick={() => goToSlide(slideIndex)} className={`text-4xl cursor-pointer ${slideIndex === currentIndex ? 'text-primary' : ''
                        }`}>
                        <RxDotFilled />
                    </div>
                ))}
            </div>

        </div>
    )
}

export default Slider