import React from 'react'
import { Link } from 'react-router-dom';
// import logo from '../assets/logo.jpeg'
import aloevera from '../assets/aloe-vera.jpeg'
import TitleBar from '../component/title/TitleBar'

const DirectSeller = () => {
    return (
        <>
            <TitleBar pagetitle="Terms and Conditions for Direct Sellers" breadcrumbs={
                <>
                    <Link to="/">Home</Link> &gt;
                    <Link to=""> Direct Sellers Terms</Link>
                </>}
            />

            {/* About swatee pvt. ltd */}
            <div className='container px-4 my-6 flex sm:flex-col lg:flex-row'>
                <div className='basis-[75%] py-6 px-8 mt-8 bg-[#f2f2f2]'>
                    {/* <img className='w-[40%]' src={logo} alt="/Swatee Logo" /> */}
                    <h2 className='py-6 text-lg'>सदस्य (DIRECT SELLER) के लिए सामान्य नियम व शर्तें-</h2>
                    {/* <p className='text-justify text-sm py-6'>
                        You can find all the Terms, Conditions and Policies related to company. For any specific query, kindly get in touch with us.
                    </p> */}
                    <ol className="text-[#4d4d4d] p-4 list-decimal text-sm">

                        <li className='p-1'>
                            आप कोई भी गैर क़ानूनी कार्य नहीं करेंगें.
                        </li>

                        <li className='p-1'>
                            कोई भी सदस्य (DS) कंपनी का कोई भी कार्य आई०डी० कार्ड (जो कंपनी द्वारा जारी किया जाता हैं) के बिना नहीं करेगा.
                        </li>

                        <li className='p-1'>
                            आप केवल उपभोक्ता को ही सदस्य (DS) बना सकते हैं.
                        </li>

                        <li className='p-1'>
                            आप उपभोक्ता को या आपके द्वारा बनाये सदस्य (DS) को प्रत्यक्ष या अप्रत्यक्ष रूप से किसी प्रकार का कोई लालच नहीं देंगें न ही कोई झूठा वादा करेंगें.
                        </li>

                        <li className='p-1'>
                            आप अपने किसी भी सदस्य (DS) को निश्चित उत्पाद बिक्री के लिए बाध्य नहीं करेंगें.
                        </li>

                        <li className='p-1'>
                            सदस्य (DS) का लाभांश प्रतिशत व बोनस प्रतिशत उसके और उसके ग्रुप द्वारा की गयी बिक्री के आधार पर ही तय होगा.
                        </li>

                        <li className='p-1'>
                            उपभोक्ता को सदस्यता देते समय ही उत्पाद, उत्पाद बिक्री पर उसे मिलने वाले लाभांश या अन्य लाभ के विषय में स्पष्ट रूप से अवगत कराना अनिवार्य होगा.
                        </li>

                        <li className='p-1'>
                            सदस्य को कंपनी के उत्पाद कंपनी के खाते में धनराशी जमा करके कम्पनी के ऑफिस से प्राप्त करने होंगे.
                        </li>

                        <li className='p-1'>
                            कंपनी सदस्य / DS के किसी भी व्यय के प्रति उत्तरदायी नहीं होगी.
                        </li>

                        <li className='p-1'>
                            लाभांश धनराशी को सदस्य (DS) के बैंक खाते में ही तय आधार पर ट्रांसफर किया जायेगा.
                        </li>

                        <li className='p-1'>
                            डायरेक्ट सेलर का लाभांश नियमानुसार टी०डी०एस० (TDS) काटकर ही दिया जायेगा.
                        </li>

                        <li className='p-1'>
                            सदस्य / उपभोक्ता की कोई शिकायत हो तो उसके निस्तारण और समाधान हेतु उसकी शिकायत तुरंत शिकायत निस्तारण समिति को अपनी उपलाइन के माध्यम से लिखित रूप से भेजना अनिवार्य होगा.
                        </li>

                        <li className='p-1'>
                            यदि कोई सदस्य / (DS) कंपनी की सदस्यता छोड़ता हैं तो उसे कंपनी को ३० दिन पुर्व लिखित रूप से सूचित करना होगा और इस अवधि में अपने सभी लेनदेन पूरे करने होंगें.
                        </li>

                        <li className='p-1'>
                            प्रत्येक सदस्य को कम्पनी द्वारा आयोजित सेमिनार में प्रतिभाग करना होगा.
                        </li>

                        <li className='p-1'>
                            प्रत्येक सदस्य को उक्त नियमों व शर्तों और कम्पनी द्वारा जारी की गयी नीतियों का अनिवार्य रूप से पालन करना होगा, ऐसा न करने वाले या इनका उल्लंघन करने वाले सदस्य के साथ कंपनी द्वारा किये गये अनुबंध को समाप्त कर दिए जाने का पूर्ण अधिकार कंपनी (SHS) के पास सुरक्षित रहेगा जो कि DS पर बाध्यकारी होगा.
                        </li>

                    </ol >
                </div>
                <div className='basis-[25%] h-[450px] ml-4 mt-8 bg-[#f2f2f2] '>
                    <h2 className='py-2 sm:text-3xl text-lg text-white text-center bg-primary'>
                        New Arrivals
                    </h2>

                    <img className='sm:max-w-[90%] mx-auto' src={aloevera} alt="/product-image" />
                </div>
            </div>
        </>
    )
}

export default DirectSeller