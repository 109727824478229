import { FaDatabase } from "react-icons/fa";
import { rozarPayKey } from "../services";
export const redirectToPage = (url) => {
  //alert('hi');
  window.location.href = url;
}


export const checkProductExistIntoState = (data, productId) => {
  //alert('hi');
  data.filter((item) => {
    if (item.id === productId) {
      return 'ram';
    }
    else {
      return 'Krishna';
    }
  })
}

export const paymentIntegration = (data) => {
  var options = {
    "key": "rzp_test_UpcETFfqkiVRBG", // Enter the Key ID generated from the Dashboard
    "amount": "1000",
    "currency": "INR",
    "description": "Acme Corp",
    "image": "https://s3.amazonaws.com/rzp-mobile/images/rzp.jpg",
    "prefill":
    {
      "email": "gaurav.kumar@example.com",
      "contact": +919900000000,
    },
    "handler": function (response) {
      alert(response.razorpay_payment_id);
    },
    "modal": {
      "ondismiss": function () {
        if (confirm("Are you sure, you want to close the form?")) {
          txt = "You pressed OK!";
          console.log("Checkout form closed by the user");
        } else {
          txt = "You pressed Cancel!";
          console.log("Complete the Payment")
        }
      }
    }
  };
  var rzp1 = new window.Razorpay(options);
  rzp1.open();
}