import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { BsArrowRightShort } from 'react-icons/bs'


// Product Category API 
const categoryURL = "/get-categories";


const ProductCategory = ({filterdata}) => {

    const [category, setCategory] = useState([]);

    useEffect(() => {
        axios.get(categoryURL)
            .then(response => {
                setCategory(response.data.data);
            })

            .catch(error => {
                console.log(error);
            });
    }, []);

    return (
        <>

            {category.map(item => (
                <Link key={item.id} to='#' onClick={()=>filterdata('cat_search',item?.id)}>
                    <div className='flex items center justify-between mb-2 text-md hover:text-primary'>
                        <p className='hover:text-primary text-[14px]'>{item.category_name}</p>
                        <BsArrowRightShort className='mr-3 text-[20px]' />
                    </div>
                </Link>
            ))}
        </>
    )
}

export default ProductCategory