import React from "react";
import { Link } from "react-router-dom";

import TitleBar from "../component/title/TitleBar";
// import newshs from '../assets/pdf/New-Income-Plan-2023.pdf'

const IncomePlans = () => {
  return (
    <div>
      <TitleBar
        pagetitle="Swatee Health Solutions Income Plans"
        breadcrumbs={
          <>
            <Link to="/">Home</Link> &gt;
            <Link to=""> Income Plans</Link>
          </>
        }
      />
      {/* About swatee pvt. ltd */}
      <div className="container px-4 flex sm:flex-col lg:flex-row">
        {/* <div className='basis-[50%] p-8'>
                    <img src={about} alt="/about-us" />
                </div> */}
        <div className="basis-[100%] py-12">
          <h2 className="py-6 sm:text-3xl">WELCOME NOW YOU ARE A DS</h2>
          <p className=" leading-[2] text-justify py-4">
            DS के रूप में आपका स्वागत हैं , आपको बधाई ! अब आप कंपनी के
            प्रोडक्ट्स पर छूट पाने के अधिकारी बन चुके हैं , आप यहाँ हैं इसका
            मतलब है कि आप अपने लिए एक अतिरिक्त आय का स्रोत भी बनाना चाहते हैं !
            तो इसके लिए सबसे पहले आप वह एग्रीमेंट जो आपने कंपनी के साथ साईन किया
            हैं उसे वेबसाइट से डाउनलोड कर ध्यानपूर्वक पढ़ें और आप अपना आइ०डी०
            कार्ड डाउनलोड करके सदैव अपने पास रक्खें !
          </p>
          <p className="text-xl text-primary text-justify font-bold">
            अब बताए और सुझाएँ गये तरीके से आगे बढ़ना शुरू करें !
          </p>
          <ol className="text-[#5C727D] list-decimal mt-2 p-4 ">
            <li className="p-1">
            इस नेटवर्क सिस्टम का इनकम प्लान आपके ग्रुप और SP (सेल पॉइंट / स्वाती पॉइंट ) के आधार पर काम करता हैं । {" "}
            </li>
            <li className="p-1">
            आपको अपना ग्रुप बनाने के लिए अपने डाउन में DS को ज्वाइन कराना होता हैं , और SP कमाने के लिए कंपनी के प्रोडक्ट्स खरीदने या बेचने होते हैं ।
            </li>
            <li>
            नेटवर्क सिस्टम का मूल लाभ लेने के लिए आपको कम से कम 50 SP (सेल पॉइंट) की खरीद या बिक्री प्रतिमाह करनी होती हैं और यदि आप 50 SP से अधिक SP (सेल पॉइंट / स्वाती पॉइंट ) की खरीद या बिक्री प्रतिमाह करते है तो आपको P-Bonus के रूप में अतिरिक्त आय होती है ।
            </li>
            <li className="text-primary p-1">
            यदि आप 50 SP से कम सेल पॉइंट कमाते हैं तो उस माह आपको कोई भी GSI नही मिलती है और यदि ऐसा लगातार तीन माह तक होता हैं तो कंपनी आपकी DSID को सीज़ कर देती हैं यानि DSID से आपके सभी अधिकार समाप्त हो जाते है ।
            </li>
            <li className="p-1">
            नेटवर्क सिस्टम में आपके डाउन में जितने भी DS ज्वाइन करते हैं वही आपका ग्रुप होता हैं उसी ग्रुप से आपको GSI  की आय होती हैं , आपका ग्रुप जितना बड़ा होता जाता हैं , आपकी आय भी उसी अनुपात में बढ़ती जाती हैं ।
            </li>
            <li className="p-1">
            आपको कंपनी के एजुकेशन सिस्टम में दी गयी ट्रेनिंग करनी चाहिए, यदि आप उस ट्रेनिंग को कर लेते हैं तो आपको बोनस के रूप में एक अतिरिक्त आय मिलने लगती हैं । {" "}
            </li>
            <li className="p-1">
            जब आप नेटवर्क सिस्टम में 16 वीं रैंक पर पहुँच जाते हैं तो कंपनी से आपको हमेशा एक फिक्स्ड इनकम लगातार आती रहती हैं !
            </li>
          </ol>
          <a
            href="https://docs.google.com/presentation/d/1Eao6IW34NkfhKXLq2RmNVM9DNT9HkoomSip_QO0gFnY/edit?usp=sharing"
            target="_blank"
            className="underline leading-[2] text-xl text-primary hover:text-secondary text-justify font-"
          >
            Download latest SHS Income Plan
          </a>
          <h3 className="py-6 sm:text-3xl">Intro of Income</h3>
          <p className=" leading-[2] text-justify">
            As a DS you earn here three type of income-
          </p>
          <ol className="text-[#5C727D] list-decimal mt-2 pl-4 ">
            <li className="p-1">Rebate or Concession up to 10%. (Look on DS Rebate Chart)</li>
            <li className="p-1">
            GSI from your network volume as Group Sale Commission (GSC). (Look on GSC percentage Chart).
            </li>
            <li>
                E-Bonus for your educational upgradation. (Look on E-Bonus percentage Chart).
            </li>
            <li>
              P-Bonus for your monthly earned SP. (Look on P-Bonus percentage Chart).
            </li>
          </ol>
        </div>
      </div>

      {/* Rebate Chart for Buyers (RCB)*/}
      <div className="container px-4 flex items-center sm:flex-col lg:flex-row">
        <div className="basis-[100%] py-6 sm:py-4">
          <h3 className="pb-4 leading-[1.8]">DS Rebate Chart (URC)</h3>
          <table className="w-full border-2 text-center">
            <thead className="bg-primary border-b-2 border-secondary">
              <tr>
                <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                  Purchase Amount From (Rs)
                </th>
                <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                  Purchase Amount To (Rs)
                </th>
                <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                  Rebate %
                </th>
              </tr>
            </thead>
            <tbody className="text-[#5C727D]">
              <tr className="border-2 ">
                <td className="border-2 bg-white p-3 text-md">0 </td>
                <td className="border-2 bg-white p-3 text-md">500</td>
                <td className="border-2 bg-white p-3 text-md">4%</td>
              </tr>
              <tr className="border-2">
                <td className="border-2 bg-gray-50 p-3 text-md">501 </td>
                <td className="border-2 bg-gray-50 p-3 text-md">1000</td>
                <td className="border-2 bg-gray-50 p-3 text-md">5%</td>
              </tr>
              <tr className="border-2 ">
                <td className="border-2 bg-white p-3 text-md">1001 </td>
                <td className="border-2 bg-white p-3 text-md">2000</td>
                <td className="border-2 bg-white p-3 text-md">6%</td>
              </tr>
              <tr className="border-2">
                <td className="border-2 bg-gray-50 p-3 text-md">2001 </td>
                <td className="border-2 bg-gray-50 p-3 text-md">4000</td>
                <td className="border-2 bg-gray-50 p-3 text-md">7%</td>
              </tr>
              <tr className="border-2 ">
                <td className="border-2 bg-white p-3 text-md">4001 </td>
                <td className="border-2 bg-white p-3 text-md">7000</td>
                <td className="border-2 bg-white p-3 text-md">8%</td>
              </tr>
              <tr className="border-2">
                <td className="border-2 bg-gray-50 p-3 text-md">7001 </td>
                <td className="border-2 bg-gray-50 p-3 text-md">10000</td>
                <td className="border-2 bg-gray-50 p-3 text-md">9%</td>
              </tr>
              <tr className="border-2 ">
                <td className="border-2 bg-white p-3 text-md">10001</td>
                <td className="border-2 bg-white p-3 text-md">Above</td>
                <td className="border-2 bg-white p-3 text-md">10%</td>
              </tr>
            </tbody>
          </table>

          {/* <p className='py-8'>
                        उदहारण – यदि कोई उपभोक्ता ५००० रूपये के हेल्थ प्रोडक्ट खरीद करता हैं तो ५००० का ८% = ४०० रूपये की छूट प्राप्त करता हैं जिसका पूर्ण विवरण इनवॉइस पर भी दिखाई देता हैं . यह छूट आपको तुरंत प्राप्त होती हैं !
                    </p> */}
        </div>
      </div>

      {/* SALE POINTS (SP) FOR THE PRODUCTS */}
      <div class="container flex flex-col items-center lg:flex-row">
        <div class="w-full lg:w-[70%] px-4 py-6">
          <h3 class="text-lg font-semibold">
            SALE POINTS (SP) FOR THE PRODUCTS
          </h3>
          <p class="text-md text-red font-bold">
            You may earn SP on Sale / Purchase of Products as described below in
            the Table
          </p>
          <p class="text-lg text-primary font-bold pb-6">
            You can also see the SP points with every product.
          </p>
          <div class="overflow-x-auto">
            <table class="min-w-full border-2 text-center sm:table-auto">
              <thead class="bg-primary border-b-2 border-secondary">
                <tr>
                  <th class="border-2 p-3 text-lg font-semibold tracking-wide">
                    Product Name
                  </th>
                  <th class="border-2 p-3 text-lg font-semibold tracking-wide">
                    SP
                  </th>
                  <th class="border-2 p-3 text-lg font-semibold tracking-wide">
                    Product Name
                  </th>
                  <th class="border-2 p-3 text-lg font-semibold tracking-wide">
                    SP
                  </th>
                  <th class="border-2 p-3 text-lg font-semibold tracking-wide">
                    Product Name
                  </th>
                  <th class="border-2 p-3 text-lg font-semibold tracking-wide">
                    SP
                  </th>
                </tr>
              </thead>
              <tbody class="text-[#5C727D]">
                <tr class="border-2">
                  <td class="border-2 bg-white p-3 text-md">Shaktaras Syrup</td>
                  <td class="border-2 bg-white p-3 text-md">9</td>
                  <td class="border-2 bg-white p-3 text-md">Swateeras Syrup</td>
                  <td class="border-2 bg-white p-3 text-md">7</td>
                  <td class="border-2 bg-white p-3 text-md">
                    Triphalagugulu Syrup
                  </td>
                  <td class="border-2 bg-white p-3 text-md">7</td>
                </tr>
                <tr class="border-2">
                  <td class="border-2 bg-gray-50 p-3 text-md">
                    Hemakshi Syrup
                  </td>
                  <td class="border-2 bg-gray-50 p-3 text-md">10</td>
                  <td class="border-2 bg-gray-50 p-3 text-md">
                    Healartho Syrup
                  </td>
                  <td class="border-2 bg-gray-50 p-3 text-md">10</td>
                  <td class="border-2 bg-gray-50 p-3 text-md">
                    Healartho Capsule
                  </td>
                  <td class="border-2 bg-gray-50 p-3 text-md">5</td>
                </tr>
                <tr class="border-2">
                  <td class="border-2 bg-white p-3 text-md">
                    Denta+ Toothpaste
                  </td>
                  <td class="border-2 bg-white p-3 text-md">2</td>
                  <td class="border-2 bg-white p-3 text-md">
                    Healartho Anti Pain Oil
                  </td>
                  <td class="border-2 bg-white p-3 text-md">4</td>
                  <td class="border-2 bg-white p-3 text-md">Denta Magic Jel</td>
                  <td class="border-2 bg-white p-3 text-md">1</td>
                </tr>
                <tr class="border-2">
                  <td class="border-2 bg-gray-50 p-3 text-md">
                    Keshav Hair Oil
                  </td>
                  <td class="border-2 bg-gray-50 p-3 text-md">4</td>
                  <td class="border-2 bg-gray-50 p-3 text-md">
                    Keshav herbal Shampoo
                  </td>
                  <td class="border-2 bg-gray-50 p-3 text-md">5</td>
                  <td class="border-2 bg-gray-50 p-3 text-md">Evo Facewash</td>
                  <td class="border-2 bg-gray-50 p-3 text-md">1</td>
                </tr>
                <tr class="border-2">
                  <td class="border-2 bg-white p-3 text-md">
                    Tensonil Tablets
                  </td>
                  <td class="border-2 bg-white p-3 text-md">6</td>
                  <td class="border-2 bg-white p-3 text-md">SSB Capsule</td>
                  <td class="border-2 bg-white p-3 text-md">15</td>
                  <td class="border-2 bg-white p-3 text-md">
                    Piles-Off Capsule
                  </td>
                  <td class="border-2 bg-white p-3 text-md">8</td>
                </tr>
                <tr class="border-2">
                  <td class="border-2 bg-gray-50 p-3 text-md">
                    Prostone Capsule
                  </td>
                  <td class="border-2 bg-gray-50 p-3 text-md">5</td>
                  <td class="border-2 bg-gray-50 p-3 text-md">
                    Gulnorm Capsule
                  </td>
                  <td class="border-2 bg-gray-50 p-3 text-md">9</td>
                  <td class="border-2 bg-gray-50 p-3 text-md">
                    Gulnorm Powder
                  </td>
                  <td class="border-2 bg-gray-50 p-3 text-md">1</td>
                </tr>
                <tr class="border-2">
                  <td class="border-2 bg-white p-3 text-md">
                    Chyawanprash Special
                  </td>
                  <td class="border-2 bg-white p-3 text-md">1</td>
                  <td class="border-2 bg-white p-3 text-md">Aloevera Jel</td>
                  <td class="border-2 bg-white p-3 text-md">1</td>
                  <td class="border-2 bg-white p-3 text-md">Dhoop batti</td>
                  <td class="border-2 bg-white p-3 text-md">0.5</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* PROMOTION PLAN CHART */}
      <div className="container px-4 flex items-center sm:flex-col lg:flex-row">
        <div className="w-full py-6 sm:py-4">
          <h3 className="pb-4 text-center sm:text-left leading-[1.8]">
            PROMOTION PLAN CHART
          </h3>
          <div className="overflow-x-auto">
            <table className="w-full border-2 text-center">
              <thead className="bg-primary border-b-2 border-secondary">
                <tr>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                    RANK
                  </th>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                    DS DESIGNATION
                  </th>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                    CLL in your Group
                  </th>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                    Network Volume
                  </th>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                    Ultra tag
                  </th>
                </tr>
              </thead>
              <tbody className="text-[#5C727D]">
                <tr className="border-2">
                  <td className="border-2 bg-white p-3 text-md">1</td>
                  <td className="border-2 bg-white p-3 text-md">Simple</td>
                  <td className="border-2 bg-white p-3 text-md">0</td>
                  <td className="border-2 bg-white p-3 text-md">0</td>
                  <td className="border-2 bg-white p-3 text-md">
                    Public Speaking
                  </td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">2</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">
                    Bronze Star
                  </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">1</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">2</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">
                    Basic Computer
                  </td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-white p-3 text-md">3</td>
                  <td className="border-2 bg-white p-3 text-md">Silver Star</td>
                  <td className="border-2 bg-white p-3 text-md">2</td>
                  <td className="border-2 bg-white p-3 text-md">4</td>
                  <td className="border-2 bg-white p-3 text-md">
                    Graphics & Adv.
                  </td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">4</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">Gold Star</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">3</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">8</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">
                    Digital Marketing
                  </td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-white p-3 text-md">5</td>
                  <td className="border-2 bg-white p-3 text-md">Emerald Gem</td>
                  <td className="border-2 bg-white p-3 text-md">4</td>
                  <td className="border-2 bg-white p-3 text-md">16</td>
                  <td className="border-2 bg-white p-3 text-md">
                    Time management
                  </td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">6</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">Topaz Gem</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">5</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">32</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">
                    Video Editing
                  </td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-white p-3 text-md">7</td>
                  <td className="border-2 bg-white p-3 text-md">Diamond Gem</td>
                  <td className="border-2 bg-white p-3 text-md">6</td>
                  <td className="border-2 bg-white p-3 text-md">64</td>
                  <td className="border-2 bg-white p-3 text-md">
                    Personal Branding
                  </td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">8</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">Champion</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">7</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">128</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">
                    Train the trainee
                  </td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-white p-3 text-md">9</td>
                  <td className="border-2 bg-white p-3 text-md">Ambassador</td>
                  <td className="border-2 bg-white p-3 text-md">8</td>
                  <td className="border-2 bg-white p-3 text-md">256</td>
                  <td className="border-2 bg-white p-3 text-md">Consulting</td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">10</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">Director</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">9</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">512</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">Draun 1</td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-white p-3 text-md">11</td>
                  <td className="border-2 bg-white p-3 text-md">Super Director</td>
                  <td className="border-2 bg-white p-3 text-md">10</td>
                  <td className="border-2 bg-white p-3 text-md">1024</td>
                  <td className="border-2 bg-white p-3 text-md">Draun 2</td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">12</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">Chief Director</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">11</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">2048</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">Draun 3</td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-white p-3 text-md">13</td>
                  <td className="border-2 bg-white p-3 text-md">Executive Director</td>
                  <td className="border-2 bg-white p-3 text-md">12</td>
                  <td className="border-2 bg-white p-3 text-md">4096</td>
                  <td className="border-2 bg-white p-3 text-md">Draun 4</td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">14</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">Presidential Director</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">13</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">8192</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">Draun 5</td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-white p-3 text-md">15</td>
                  <td className="border-2 bg-white p-3 text-md">Royal Director</td>
                  <td className="border-2 bg-white p-3 text-md">14</td>
                  <td className="border-2 bg-white p-3 text-md">16384</td>
                  <td className="border-2 bg-white p-3 text-md">Draun 6</td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">16</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">Supremo Director</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">15</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">32768</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">Draun 7</td>
                </tr>
                
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* PROMOTION PLAN CHART 2*/}
      <div className="container px-4 flex items-center sm:flex-col lg:flex-row">
        <div className="w-full py-6 sm:py-4">
          <h3 className="pb-4 leading-[1.8]">PROMOTION PLAN CHART</h3>
          <div className="overflow-x-auto">
            <table className="w-full border-2 text-center">
              <thead className="bg-primary border-b-2 border-secondary">
                <tr>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                    RANK
                  </th>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                    DS DESIGNATION
                  </th>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                    CLL in your Group
                  </th>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                    Network Volume
                  </th>
                </tr>
              </thead>
              <tbody className="text-[#5C727D]">
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">10 </td>
                  <td className="border-2 bg-white p-3 text-md">DIRECTOR</td>
                  <td className="border-2 bg-white p-3 text-md">9</td>
                  <td className="border-2 bg-white p-3 text-md">512</td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">11 </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">
                    SUPER DIRECTOR
                  </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">10</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">1024</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">12 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    CHIEF DIRECTOR
                  </td>
                  <td className="border-2 bg-white p-3 text-md">11</td>
                  <td className="border-2 bg-white p-3 text-md">2048</td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">13 </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">
                    EXECUTIVE DIRECTOR
                  </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">12</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">4096</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">14 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    PRESIDENTIAL DIRECTOR{" "}
                  </td>
                  <td className="border-2 bg-white p-3 text-md">13</td>
                  <td className="border-2 bg-white p-3 text-md">8192</td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">15 </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">
                    ROYAL DIRECTOR{" "}
                  </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">14</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">16384</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">16 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    SUPREMO DIRECTOR{" "}
                  </td>
                  <td className="border-2 bg-white p-3 text-md">15</td>
                  <td className="border-2 bg-white p-3 text-md">32768</td>
                </tr>
                {/* Add more table rows here */}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* GSC percentage*/}
      <div className="container px-4 flex items-center sm:flex-col lg:flex-row">
        <div className="w-full py-6 sm:py-4">
          <h3 className="pb-4 leading-[1.8]">GSC Percentage</h3>
          <div className="overflow-x-auto">
            <table className="w-full border-2 text-center">
              <thead className="bg-primary border-b-2 border-secondary">
                <tr>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                    RANK
                  </th>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                    DS DESIGNATION
                  </th>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                    DS LEVEL
                  </th>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                    Your Earn SP Distribution in %
                  </th>
                </tr>
              </thead>
              <tbody className="text-[#5C727D]">
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">1 </td>
                  <td className="border-2 bg-white p-3 text-md">Simple</td>
                  <td className="border-2 bg-white p-3 text-md">You</td>
                  <td className="border-2 bg-white p-3 text-md">21 %</td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">2 </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">
                    Bronze Star
                  </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">Level 1</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">11 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">3 </td>
                  <td className="border-2 bg-white p-3 text-md">Silver Star</td>
                  <td className="border-2 bg-white p-3 text-md">Level 2</td>
                  <td className="border-2 bg-white p-3 text-md">10 %</td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">4 </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">Gold Star</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">Level 3</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">9 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">5 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    Emerald Gem{" "}
                  </td>
                  <td className="border-2 bg-white p-3 text-md">Level 4</td>
                  <td className="border-2 bg-white p-3 text-md">8 %</td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">6 </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">
                    Topaz Gem{" "}
                  </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">Level 5</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">7 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">7 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    Diamond Gem{" "}
                  </td>
                  <td className="border-2 bg-white p-3 text-md">Level 6</td>
                  <td className="border-2 bg-white p-3 text-md">6 %</td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">8 </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">Champion </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">Level 7</td>
                  <td className="border-2 bg-gray-50 p-3 text-md">5 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">9 </td>
                  <td className="border-2 bg-white p-3 text-md">Ambassador </td>
                  <td className="border-2 bg-white p-3 text-md">Level 8</td>
                  <td className="border-2 bg-white p-3 text-md">4 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">10 </td>
                  <td className="border-2 bg-white p-3 text-md">DIRECTOR </td>
                  <td className="border-2 bg-white p-3 text-md">Level 9</td>
                  <td className="border-2 bg-white p-3 text-md">3.5 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">11 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    SUPER DIRECTOR{" "}
                  </td>
                  <td className="border-2 bg-white p-3 text-md">Level 10 </td>
                  <td className="border-2 bg-white p-3 text-md">3 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">12 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    CHIEF DIRECTOR{" "}
                  </td>
                  <td className="border-2 bg-white p-3 text-md">Level 11 </td>
                  <td className="border-2 bg-white p-3 text-md">2.5 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">13 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    EXECUTIVE DIRECTOR{" "}
                  </td>
                  <td className="border-2 bg-white p-3 text-md">Level 12</td>
                  <td className="border-2 bg-white p-3 text-md">2 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">14 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    PRESIDENTIAL DIRECTOR{" "}
                  </td>
                  <td className="border-2 bg-white p-3 text-md">Level 13</td>
                  <td className="border-2 bg-white p-3 text-md">1.5 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">15 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    ROYAL DIRECTOR{" "}
                  </td>
                  <td className="border-2 bg-white p-3 text-md">LEVEL 14</td>
                  <td className="border-2 bg-white p-3 text-md">1 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">16 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    SUPREMO DIRECTOR{" "}
                  </td>
                  <td className="border-2 bg-white p-3 text-md">
                    SUPREMO DIRECTOR
                  </td>
                  <td className="border-2 bg-white p-3 text-md">
                    Fixed Income
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* E Bonus Percentage*/}
      <div className="container px-4 flex items-center sm:flex-col lg:flex-row">
        <div className="w-full py-6 sm:py-4">
          <h3 className="pb-4 leading-[1.8]">E-Bonus Percentage Chart</h3>
          <div className="overflow-x-auto">
            <table className="w-full border-2 text-center">
              <thead className="bg-primary border-b-2 border-secondary">
                <tr>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                    RANK
                  </th>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                    DS DESIGNATION
                  </th>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                    OPENED TRAININGS
                  </th>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                    Training Bonus % of your Income
                  </th>
                </tr>
              </thead>
              <tbody className="text-[#5C727D]">
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">1 </td>
                  <td className="border-2 bg-white p-3 text-md">Simple UT</td>
                  <td className="border-2 bg-white p-3 text-md">
                    Basic Training
                  </td>
                  <td className="border-2 bg-white p-3 text-md">5 %</td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">2 </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">
                    Bronze Star UT
                  </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">
                    Field Training
                  </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">6.1 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">3 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    Silver Star UT
                  </td>
                  <td className="border-2 bg-white p-3 text-md">
                    Team Building
                  </td>
                  <td className="border-2 bg-white p-3 text-md">6.2 %</td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">4 </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">
                    Gold Star UT
                  </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">
                    Products Knowledge
                  </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">6.3 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">5 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    Emerald Gem UT{" "}
                  </td>
                  <td className="border-2 bg-white p-3 text-md">Behaviour</td>
                  <td className="border-2 bg-white p-3 text-md">7.1 %</td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">6 </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">
                    Topaz Gem UT
                  </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">
                    Presentation
                  </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">7.2 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">7 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    Diamond Gem UT
                  </td>
                  <td className="border-2 bg-white p-3 text-md">
                    Basic Computer
                  </td>
                  <td className="border-2 bg-white p-3 text-md">7.3 %</td>
                </tr>
                <tr className="border-2">
                  <td className="border-2 bg-gray-50 p-3 text-md">8 </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">
                    Champion UT
                  </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">
                    Speech / Meeting
                  </td>
                  <td className="border-2 bg-gray-50 p-3 text-md">8 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">9 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    Ambassador UT
                  </td>
                  <td className="border-2 bg-white p-3 text-md">
                    Moral Values
                  </td>
                  <td className="border-2 bg-white p-3 text-md">9 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">10 </td>
                  <td className="border-2 bg-white p-3 text-md">DIRECTOR </td>
                  <td className="border-2 bg-white p-3 text-md">
                    Draun Season 1
                  </td>
                  <td className="border-2 bg-white p-3 text-md">10 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">11 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    SUPER DIRECTOR{" "}
                  </td>
                  <td className="border-2 bg-white p-3 text-md">
                    Draun Season 2
                  </td>
                  <td className="border-2 bg-white p-3 text-md">10.1 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">12 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    CHIEF DIRECTOR{" "}
                  </td>
                  <td className="border-2 bg-white p-3 text-md">
                    Draun Season 3
                  </td>
                  <td className="border-2 bg-white p-3 text-md">10.2 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">13 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    EXECUTIVE DIRECTOR{" "}
                  </td>
                  <td className="border-2 bg-white p-3 text-md">
                    Draun Season 4
                  </td>
                  <td className="border-2 bg-white p-3 text-md">10.3 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">14 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    PRESIDENTIAL DIRECTOR{" "}
                  </td>
                  <td className="border-2 bg-white p-3 text-md">
                    Draun Season 5
                  </td>
                  <td className="border-2 bg-white p-3 text-md">10.4 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">15 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    ROYAL DIRECTOR{" "}
                  </td>
                  <td className="border-2 bg-white p-3 text-md">
                    Draun Season 6
                  </td>
                  <td className="border-2 bg-white p-3 text-md">10.5 %</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">16 </td>
                  <td className="border-2 bg-white p-3 text-md">
                    SUPREMO DIRECTOR{" "}
                  </td>
                  <td className="border-2 bg-white p-3 text-md">
                    Draun Season 7
                  </td>
                  <td className="border-2 bg-white p-3 text-md">11%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* PBonous Percentage*/}
      <div className="container px-4 flex items-center sm:flex-col lg:flex-row">
        <div className="w-full py-6 sm:py-4">
          <h3 className="pb-4 leading-[1.8]">P-BONUS percentage Chart</h3>
          <div className="overflow-x-auto">
            <table className="w-full border-2 text-center">
              <thead className="bg-primary border-b-2 border-secondary">
                <tr>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                    RANK
                  </th>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide" colspan="2">
                  Range For Accumulate SP in DS Account in a month
                  </th>
                  <th className="border-2 p-3 text-lg font-semibold tracking-wide">
                  % P-Bonus of Earned Income in SP.
                  </th>
                </tr>
              </thead>
              <tbody className="text-[#5C727D]">
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">1 </td>
                  <td className="border-2 bg-white p-3 text-md">51</td>
                  <td className="border-2 bg-white p-3 text-md">100</td>
                  <td className="border-2 bg-white p-3 text-md">2</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">2 </td>
                  <td className="border-2 bg-white p-3 text-md">101</td>
                  <td className="border-2 bg-white p-3 text-md">150</td>
                  <td className="border-2 bg-white p-3 text-md">2.5</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">3 </td>
                  <td className="border-2 bg-white p-3 text-md">151</td>
                  <td className="border-2 bg-white p-3 text-md">200</td>
                  <td className="border-2 bg-white p-3 text-md">3</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">4 </td>
                  <td className="border-2 bg-white p-3 text-md">201</td>
                  <td className="border-2 bg-white p-3 text-md">250</td>
                  <td className="border-2 bg-white p-3 text-md">3.5</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">5 </td>
                  <td className="border-2 bg-white p-3 text-md">251</td>
                  <td className="border-2 bg-white p-3 text-md">300</td>
                  <td className="border-2 bg-white p-3 text-md">4</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">6 </td>
                  <td className="border-2 bg-white p-3 text-md">301</td>
                  <td className="border-2 bg-white p-3 text-md">350</td>
                  <td className="border-2 bg-white p-3 text-md">4.5</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">7 </td>
                  <td className="border-2 bg-white p-3 text-md">351</td>
                  <td className="border-2 bg-white p-3 text-md">400</td>
                  <td className="border-2 bg-white p-3 text-md">5</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">8 </td>
                  <td className="border-2 bg-white p-3 text-md">401</td>
                  <td className="border-2 bg-white p-3 text-md">450</td>
                  <td className="border-2 bg-white p-3 text-md">5.5</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">9 </td>
                  <td className="border-2 bg-white p-3 text-md">451</td>
                  <td className="border-2 bg-white p-3 text-md">500</td>
                  <td className="border-2 bg-white p-3 text-md">6</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">10 </td>
                  <td className="border-2 bg-white p-3 text-md">501</td>
                  <td className="border-2 bg-white p-3 text-md">600</td>
                  <td className="border-2 bg-white p-3 text-md">7</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">11 </td>
                  <td className="border-2 bg-white p-3 text-md">601</td>
                  <td className="border-2 bg-white p-3 text-md">700</td>
                  <td className="border-2 bg-white p-3 text-md">8</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">12 </td>
                  <td className="border-2 bg-white p-3 text-md">701</td>
                  <td className="border-2 bg-white p-3 text-md">800</td>
                  <td className="border-2 bg-white p-3 text-md">9</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">13 </td>
                  <td className="border-2 bg-white p-3 text-md">801</td>
                  <td className="border-2 bg-white p-3 text-md">900</td>
                  <td className="border-2 bg-white p-3 text-md">10</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">14 </td>
                  <td className="border-2 bg-white p-3 text-md">901</td>
                  <td className="border-2 bg-white p-3 text-md">1000</td>
                  <td className="border-2 bg-white p-3 text-md">11</td>
                </tr>
                <tr className="border-2 ">
                  <td className="border-2 bg-white p-3 text-md">15 </td>
                  <td className="border-2 bg-white p-3 text-md">901</td>
                  <td className="border-2 bg-white p-3 text-md">Above</td>
                  <td className="border-2 bg-white p-3 text-md">15</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* examples */}
      <div className="container px-4 my-6 flex sm:flex-col lg:flex-row">
        <div className="basis-[100%] py-6 px-8 mt-8 bg-[#f2f2f2]">
          {/* <img className='w-[40%]' src={logo} alt="/Swatee Logo" /> */}
          <h2 className="py-6 text-lg">Example</h2>
          {/* <p className='text-justify text-sm py-6'>
                You can find all the Terms, Conditions and Policies related to company. For any specific query, kindly get in touch with us.
            </p> */}
          <ol className="text-[#4d4d4d] p-4 list-decimal text-sm">
            <li className="p-1">Let suppose you are a Simple DS.</li>

            <li className="p-1">If you accumulate  500 SP for the month.</li>

            <li className="p-1">And your earned Income = 500 * 21 / 100 = 105 SP.</li>

            <li className="p-1">
            If You has taken a training in company education system.
            </li>

            <li className="p-1">Than your E-Bonus = 105 * 5 / 100 = 5.25 SP.</li>
            <li className="p-1">
              Than your P-Bonus = 105 * 6 / 100 = 6.30 SP
            </li>
            <li className="p-1">
            Your total payable income = 105 + 5.25 + 6.30 = 116.55 SP. = Rs. 1165.50 (SP@Rs.10)
            </li>
          </ol>
          <ol className="text-[#4d4d4d] p-4 list-decimal text-sm">
            <li className="p-1">Let suppose you are Topaz Gem DS.</li>

            <li className="p-1">
            If you accumulate 1500 SP for the month.
            </li>

            <li className="p-1">And suppose your earned income  = 200 SP.</li>

            <li className="p-1">
            If you has taken a specific trainings in the company education system.
            </li>

            <li className="p-1">
             Than your E-Bonus = 200* 7.2 /100 = 14.4 SP
            </li>
            <li className="p-1">
            Than your P-Bonus = 200 * 15 / 100 =  30 SP
                        </li>
            <li className="p-1">
                        Your total payable income = 200+14.40+30 = 244.4 SP=Rs. 2444.0 (@ 1 SP=Rs.10)
            </li>
          </ol>
        </div>
        
      </div>

      {/* good luck */}
      <div className="container px-4 flex sm:flex-col lg:flex-row">
        {/* <div className='basis-[50%] p-8'>
                    <img src={about} alt="/about-us" />
                </div> */}
        <div className="basis-[100%] py-2">
          <h2 className="py-2 sm:text-3xl">Good Luck</h2>
          <p className=" leading-[2] text-justify py-4">
          आशा है  कि आप कंपनी के इस नेटवर्क सिस्टम को अच्छे से समझ गये होंगें और अब आप लोगों को अपने साथ जोड़ कर अपने ग्रुप को विशालकाय स्वरूप दे पायेंगें और एक ऐसी मासिक आय को ले पायेंगें जो आपके सपनों को पूरा करेगी ! 
          </p>
          <p className="text-xl text-primary text-justify font-bold">
          We hope you’ll use these tips to go out and deliver a memorable pitch for your product or service ! ………….YOUR’S SWATEE. 
          </p>     
        </div>
      </div>
    </div>
  );
};

export default IncomePlans;
