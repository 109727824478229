import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { services } from "../../services";
import axios from "axios";
import swal from "sweetalert";

// All countries
const countries = [
  "Select Country",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  // ... (list of all countries)
];
// All States
const states = [
  "Select State",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
  "Other",
  // ... (list of all states)
];

const Addresses = () => {
  let navigate = useNavigate();
  const formRef = useRef();
  const [userDataMain, setUserDataMain] = useState([]);
  const [initialData, setInitialData] = useState({});
  // const [shipToDifferentAddress, setShipToDifferentAddress] = useState(false);

  const getUser = async () => {
    try {
      const response = await axios.get(services.getUsers);
      if (response?.status !== "error") {
        // console.log(response?.data?.data[0]);
        setUserDataMain(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const defaultAddress = {
    user_street: "",
    user_city: "",
    user_district: "",
    user_state: "Select State",
    user_country: "Select Country",
    user_postcode: "",
    user_shipping_street: "",
    user_shipping_city: "",
    user_shipping_district: "",
    user_shipping_state: "Select State",
    user_shipping_country: "Select Country",
    user_shipping_postcode: "",
  };

  useEffect(() => {
    setInitialData({
      ...defaultAddress,
      // Update values based on the fetched user data
      user_street: userDataMain[0]?.user_detail?.user_street || "",
      user_city: userDataMain[0]?.user_detail?.user_city || "",
      user_district: userDataMain[0]?.user_detail?.user_district || "",
      user_state: userDataMain[0]?.user_detail?.user_state || "Select State",
      user_country:
        userDataMain[0]?.user_detail?.user_country || "Select Country",
      user_postcode: userDataMain[0]?.user_detail?.user_postcode || "",
      user_shipping_street:
        userDataMain[0]?.user_detail?.user_shipping_street || "",
      user_shipping_city:
        userDataMain[0]?.user_detail?.user_shipping_city || "",
      user_shipping_district:
        userDataMain[0]?.user_detail?.user_shipping_district || "",
      user_shipping_state:
        userDataMain[0]?.user_detail?.user_shipping_state || "Select State",
      user_shipping_country:
        userDataMain[0]?.user_detail?.user_shipping_country || "Select Country",
      user_shipping_postcode:
        userDataMain[0]?.user_detail?.user_shipping_postcode || "",
    });
  }, [userDataMain]);

  // Function to handle the "Update Address" button click and send data to the backend
  const updateUserAddress = async (formData) => {
    try {
      const response = await axios.post(services.updateUser, formData);
      swal("Address Updated successfully.");
      navigate("/myaccount");
    } catch (error) {
      console.log("Error updating user address:", error);
    }
  };

  // Form Validations
  let billingForm = {};
  billingForm = Yup.object().shape({
    user_street: Yup.string()
      .min(2, "Please enter House Details")
      .max(100, "Please enter House Details")
      .required("Please enter House Details"),
    user_city: Yup.string()
      .min(2, "Please enter correct City Details")
      .max(100, "Please enter correct City Details"),
    user_district: Yup.string()
      .min(2, "Please enter correct city Details")
      .max(100, "Please enter correct city Details")
      .required("Please enter  city Details"),
    user_state: Yup.string()
      .min(2, "Please enter correct state Details")
      .max(100, "Please enter correct state Details")
      .required("Please enter state Details"),
    user_country: Yup.string()
      .min(2, "Please enter correct country Details")
      .max(100, "Please enter correct country Details")
      .required("Please enter country Details"),
    user_postcode: Yup.number()
      .min(1000, "zipcode Too Short!")
      .max(9999999, "zip code Too Long!")
      .required("Zipcode Required")
      .typeError("Must be a number type"),

    // Shipping Address
    user_shipping_street: Yup.string()
      .min(2, "Please enter shipping House Details")
      .max(100, "Please enter shipping House Details")
      .required("Please enter shipping House Details"),
    user_shipping_city: Yup.string()
      .min(2, "Please enter correct shipping City Details")
      .max(100, "Please enter correct shipping City Details"),
    user_shipping_district: Yup.string()
      .min(2, "Please enter correct shipping city Details")
      .max(100, "Please enter correct shipping city Details")
      .required("Please enter shipping city Details"),
    user_shipping_state: Yup.string()
      .min(2, "Please enter correct shipping state Details")
      .max(100, "Please enter correct shipping state Details")
      .required("Please enter shipping state Details"),
    user_shipping_country: Yup.string()
      .min(2, "Please enter correct shipping country Details")
      .max(100, "Please enter correct shipping country Details")
      .required("Please enter shipping country Details"),
    user_shipping_postcode: Yup.number()
      .min(1000, "zipcode Too Short!")
      .max(9999999, "zip code Too Long!")
      .required("Zipcode Required")
      .typeError("Must be a number type"),
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialData}
        validationSchema={billingForm}
        onSubmit={(values) => {
          // same shape as initial values
          //console.log(values);
          updateUserAddress(values);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="errorName">
              {/* Billing Address */}
              {errors.user_street && touched.user_street ? (
                <div className="errorList">{errors.user_street}</div>
              ) : null}
              {errors.user_city && touched.user_city ? (
                <div className="errorList">{errors.user_city}</div>
              ) : null}
              {errors.user_district && touched.user_district ? (
                <div className="errorList">{errors.user_district}</div>
              ) : null}
              {errors.user_state && touched.user_state ? (
                <div className="errorList">{errors.user_state}</div>
              ) : null}
              {errors.user_country && touched.user_country ? (
                <div className="errorList">{errors.user_country}</div>
              ) : null}
              {errors.user_postcode && touched.user_postcode ? (
                <div className="errorList">{errors.user_postcode}</div>
              ) : null}
              {/* shipping Address */}
              {errors.user_shipping_street && touched.user_shipping_street ? (
                <div className="errorList">{errors.user_shipping_street}</div>
              ) : null}
              {errors.user_shipping_city && touched.user_shipping_city ? (
                <div className="errorList">{errors.user_shipping_city}</div>
              ) : null}
              {errors.user_shipping_district &&
              touched.user_shipping_district ? (
                <div className="errorList">{errors.user_shipping_district}</div>
              ) : null}
              {errors.user_shipping_state && touched.user_shipping_state ? (
                <div className="errorList">{errors.user_shipping_state}</div>
              ) : null}
              {errors.user_shipping_country && touched.user_shipping_country ? (
                <div className="errorList">{errors.user_shipping_country}</div>
              ) : null}
              {errors.user_shipping_postcode &&
              touched.user_shipping_postcode ? (
                <div className="errorList">{errors.user_shipping_postcode}</div>
              ) : null}
            </div>

            {/* Billing Address */}
            <div>
              <h3 className="py-6">Billing Address:</h3>
              <Field
                type="text"
                //   id="house_no"
                name="user_street"
                className="max-w-[49%] h-[40px] bg-[#e9e9e9] mr-[2%] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                placeholder="House number and street name*"
                required
                //value={userDataMain[0]?.user_detail?.user_street||''}
                // onChange={(e) => setEmail(e.target.value)}
              />

              <Field
                type="text"
                //   id="apartment"
                name="user_city"
                className="max-w-[49%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                placeholder="Village / City*"
                required
                //value={userDataMain[0]?.user_detail?.user_apartment}
                // onChange={(e) => setEmail(e.target.value)}
              />

              <Field
                type="text"
                //   id="town_city"
                name="user_district"
                className="max-w-[49%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                placeholder="District*"
                required
                //value={userDataMain[0]?.user_detail?.user_city}
                // onChange={(e) => setEmail(e.target.value)}
              />

              <Field
                as="select"
                name="user_state"
                className="max-w-[49%] ml-[2%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
              >
                {states.map((states) => (
                  <option
                    key={states}
                    value={states}
                    //selected={((userDataMain[0]?.user_detail?.user_state ===states)?true:false)}
                  >
                    {states}
                  </option>
                ))}
              </Field>

              <Field
                as="select"
                name="user_country"
                className="max-w-[49%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
              >
                {countries.map((countries) => (
                  <option
                    key={countries}
                    value={countries}
                    //selected={((userDataMain[0]?.user_detail?.user_state ===states)?true:false)}
                  >
                    {countries}
                  </option>
                ))}
              </Field>

              <Field
                type="tel"
                //   id="zip_code"
                name="user_postcode"
                className="max-w-[49%] h-[40px] ml-[2%] border-[2px] border-[#e9e9e9] bg-[#e9e9e9] outline-none focus-within:border-primary transition-all text-[#000] text-sm w-full p-2 mb-6"
                placeholder="Zip*"
                required
                //value={userDataMain[0]?.user_detail?.user_postcode}
                // onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            {/* Shipping Address */}
            <div>
              {/* Shipping address form Fields */}
              <div className="inline-block">
                {/* address */}
                <div>
                  <h3 className="py-6">Shipping Address:</h3>
                  <Field
                    type="text"
                    //   id="house_no"
                    name="user_shipping_street"
                    className="max-w-[49%] h-[40px] bg-[#e9e9e9] mr-[2%] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                    placeholder="House number and street name*"
                    required
                    //value={userDataMain[0]?.user_detail?.user_street||''}
                    // onChange={(e) => setEmail(e.target.value)}
                  />

                  <Field
                    type="text"
                    //   id="apartment"
                    name="user_shipping_city"
                    className="max-w-[49%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                    placeholder="Village / City*"
                    required
                    //value={userDataMain[0]?.user_detail?.user_apartment}
                    // onChange={(e) => setEmail(e.target.value)}
                  />

                  <Field
                    type="text"
                    //   id="town_city"
                    name="user_shipping_district"
                    className="max-w-[49%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                    placeholder="District*"
                    required
                    //value={userDataMain[0]?.user_detail?.user_city}
                    // onChange={(e) => setEmail(e.target.value)}
                  />

                  <Field
                    as="select"
                    name="user_shipping_state"
                    className="max-w-[49%] ml-[2%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                  >
                    {states.map((states) => (
                      <option
                        key={states}
                        value={states}
                        //selected={((userDataMain[0]?.user_detail?.user_state ===states)?true:false)}
                      >
                        {states}
                      </option>
                    ))}
                  </Field>

                  <Field
                    as="select"
                    name="user_shipping_country"
                    className="max-w-[49%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                  >
                    {countries.map((countries) => (
                      <option
                        key={countries}
                        value={countries}
                        //selected={((userDataMain[0]?.user_detail?.user_state ===states)?true:false)}
                      >
                        {countries}
                      </option>
                    ))}
                  </Field>

                  <Field
                    type="tel"
                    //   id="zip_code"
                    name="user_shipping_postcode"
                    className="max-w-[49%] h-[40px] ml-[2%] border-[2px] border-[#e9e9e9] bg-[#e9e9e9] outline-none focus-within:border-primary transition-all text-[#000] text-sm w-full p-2 mb-6"
                    placeholder="Zip*"
                    required
                    //value={userDataMain[0]?.user_detail?.user_postcode}
                    // onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <button className="btn">Update Address</button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Addresses;
