import React, { useContext } from 'react'
import { useState, useEffect } from 'react';

//import shoppingCartContext from '../context/shoppingCartContext';

const productUrl = '/get-products';

const ShoppingCart = () => {
    //const globalData = useContext(shoppingCartContext);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(productUrl);
                setProducts(response.data.data);
                console.log(setProducts);
            } catch (error) {
                console.log(error);
            }
        };
        fetchProducts();
    }, []);

    return (
        <>
            <h1>sfsj</h1>
        </>
    )
}

export default ShoppingCart