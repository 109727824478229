import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { services } from '../../services';
const Orders = () => {

    const [orderToShow, setOrderToShow] = useState({});

    useEffect(() => {
        axios.get(services.getOrder).then(res => {
            // console.log(res);
            setOrderToShow(res?.data);
        }).catch(e => {
            console.log(e);
        })
    }, []);


    return (
        <>
            <table className='w-full text-left table-fixed sm:table-auto'>
                <thead className='bg-primary text-white'>
                    <tr>
                        <th className="p-2">Billing Date</th>
                        {/* <th className="p-2">Delivery Type</th> */}
                        <th className="p-2"> Order ID</th>
                        <th className="p-2">Order Status</th>
                        <th className="p-2">Order Total Price</th>
                        {/* <th className="p-2">Order Reciept Number</th> */}
                        <th className="p-2"> Payment Status</th>
                        {/* <td>item</td> */}
                    </tr>
                </thead>
                <tbody>
                    {orderToShow?.data?.map((item, index) => {
                        return (
                            <tr key={index} className='border-b'>
                                <td className="p-2">{item.formated_billing_date}</td>
                                {/* <td className="p-2">{item.delivery_type}</td> */}
                                <td className="p-2">{item.id}</td>
                                <td className="p-2">{item.order_status}</td>
                                <td className="p-2">{item.total_payable_amount}</td>
                                {/* <td className="p-2">{item.payment_reciept_number}</td> */}
                                <td className="p-2">{item.payment_status}</td>
                                {/* <td>{item}</td> */}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    )
}

export default Orders
