import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import TitleBar from '../title/TitleBar';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import Button from '../global/Button';
import swal from 'sweetalert';

const Register = () => {
    let navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [validation, setValidation] = useState('');
    const [loading, setLoading] = useState(false);

    const [user, setUser] = useState({
        first_name: '',
        last_name: '',
        mobile: '',
        email: '',
        password: '',
        password_confirmation: '',
    });

    const handleChange = (event) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value,
        });
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        setValidation('');
        setLoading(true);

        try {
            const response = await axios.post('/signup', user);
            console.log(response.data);
            swal(
                'You have successfully registered!',
                'Now you can order swatee products.'
            );
            // Automatically log in the user after successful registration
            const { mobile, password } = user;
            const loginResponse = await axios.post('/login', { mobile, password });
            localStorage.setItem('token', loginResponse.data.token);
            navigate('/shop');
        } catch (error) {
            setValidation(error.response.data);
        }

        setLoading(false);
    };
    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    };

    return (
        <div className='w-full'>
            <TitleBar
                pagetitle='Register to Swatee Health Solutions'
                breadcrumbs={
                    <>
                        <Link to='/'>Home</Link> &gt;
                        <Link to=''> Register</Link>
                    </>
                }
            />
            <div className='max-w-[1200px] mx-auto px-4 py-12'>
                <h2 className='text-center'>Register <br />Your Account</h2>
                <p className='text-center pt-4'>Unlock Exclusive Offers and Deals by Registering on Swatee Website</p>
            </div>
            <div className='max-w-[1200px] flex mx-auto px-4 py-62 sm:flex-col'>
                <div className='basis-[50%] mx-auto mb-8 px-8'>
                    <form onSubmit={submitHandler}>
                        <input
                            className='h-[60px] border-[2px] outline-none focus-within:border-primary transition-all border-[#e4ecf2] text-[#000] text-sm w-full p-6 mb-8'
                            placeholder='First Name*'
                            required
                            type='text'
                            id='first_name'
                            name='first_name'
                            value={user.first_name}
                            onChange={handleChange}
                        />
                        {validation && validation?.errors?.first_name && (
                            <p className="error_validation">{validation?.errors?.first_name[0]}</p>
                        )}

                        <input
                            className='h-[60px] border-[2px] outline-none focus-within:border-primary transition-all border-[#e4ecf2] text-[#000] text-sm w-full p-6 mb-8'
                            placeholder='Last Name*'
                            required
                            type='text'
                            id='last_name'
                            name='last_name'
                            value={user.last_name}
                            onChange={handleChange}
                        />
                        {validation && validation?.errors?.last_name && (
                            <p className="error_validation">{validation?.errors?.last_name[0]}</p>
                        )}

                        <input
                            className='h-[60px] border-[2px] outline-none focus-within:border-primary transition-all border-[#e4ecf2] text-[#000] text-sm w-full p-6 mb-8'
                            placeholder='Mobile No.*'
                            required
                            type='tel'
                            id='phone'
                            name='mobile'
                            value={user.mobile}
                            onChange={handleChange}
                        />
                        {validation && validation?.errors?.mobile && (
                            <p className="error_validation">{validation?.errors?.mobile[0]}</p>
                        )}

                        <input
                            className='h-[60px] border-[2px] border-[#e4ecf2] outline-none focus-within:border-primary transition-all text-[#000] text-sm w-full p-6 mb-8'
                            placeholder='Email'
                            type='email'
                            id='emailaddress'
                            name='email'
                            value={user.email}
                            onChange={handleChange}
                        />
                        {/* {error && error.email && (
                            <p className="error_validation">{error.email[0]}</p>
                        )} */}

                        <div className='relative'>
                            <input
                                className='h-[60px] border-[2px] border-[#e4ecf2] outline-none focus-within:border-primary transition-all text-[#000] text-sm w-full p-6 mb-8'
                                placeholder='Password*'
                                required
                                type={showPassword ? 'text' : 'password'}
                                id='password'
                                name='password'
                                value={user.password}
                                onChange={handleChange}
                            />
                            <span
                                className='absolute top-[35%] right-2 transform -translate-y-1/2 cursor-pointer text-primary'
                                onClick={handleTogglePassword}
                            >
                                {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                            </span>
                            {validation && validation?.errors?.password && (
                                <p className="error_validation">{validation?.errors?.password[0]}</p>
                            )}
                        </div>

                        <div className='relative'>
                            <input
                                className='h-[60px] border-[2px] border-[#e4ecf2] outline-none focus-within:border-primary transition-all text-[#000] text-sm w-full p-6 mb-8'
                                placeholder='Confirm Password*'
                                required
                                type={showConfirmPassword ? 'text' : 'password'}
                                id='confrmpass'
                                name='password_confirmation'
                                value={user.password_confirmation}
                                onChange={handleChange}
                            />
                            <span
                                className='absolute top-[35%] right-2 transform -translate-y-1/2 cursor-pointer text-primary'
                                onClick={handleToggleConfirmPassword}
                            >
                                {showConfirmPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                            </span>
                            {validation && validation?.errors?.password_confirmation && (
                                <p className="error_validation">{validation?.errors?.password_confirmation[0]}</p>
                            )}
                        </div>

                        <input
                            className='w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800'
                            required
                            id='terms'
                            type='checkbox'
                            name='checkbox'
                        />

                        <label htmlFor='terms' className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'>
                            I agree with the <Link to='/terms-policies' className='text-primary hover:underline'>terms and conditions</Link>
                        </label><br /><br />

                        <Button
                            className='text-white text-xl bg-primary px-12 py-4'
                            buttonName='Create Account'
                            type='submit'
                        />
                        {validation && !Array.isArray(validation) && (
                            <p className='text-[red] text-sm mt-2'>{validation?.message}</p>
                        )}
                        {loading && (
                            <p className='text-left'>Please Wait...</p>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Register;
