import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { services } from "../../services";
import CartTotal from "../cart/CartTotal";
import OrderPayment from "./OrderPayment";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { cartDataRemove } from "../../actions";

import TitleBar from "../title/TitleBar";
import FaqToggle from "../faq/FaqToggle";
import LoginApi from "../login/LoginApi";

// All countries
const countries = [
  "Select Country",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  // ... (list of all countries)
];
// All States
const states = [
  "Select State",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
  "Other",
  // ... (list of all states)
];

const NewCheckout = () => {
  const isLoggedIn = localStorage.getItem("token");

  const formRef = useRef();
  const data = useSelector((state) => state.cartReducer);
  const dispatch = useDispatch();
  //const taxvalue=data?.cartCalculation?.total
  let navigate = useNavigate();
  // setTaxvalue(data?.cartCalculation?.total);
  const [taxvalue, setTaxvalue] = useState(0);
  const [userDataMain, setUserDataMain] = useState([]);
  const [shipToDifferentAddress, setShipToDifferentAddress] = useState(false);
  // const [showLoginForm, setShowLoginForm] = useState(false);
  const [initialData, setInitialData] = useState({});
  // const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [couponData, setCouponData] = useState({});

  const toggleLoginForm = () => {
    setShowLoginForm((prevState) => !prevState);
  };

  const loadScript = (src1) => {
    return new Promise((resolve) => {
      let script = document.createElement("script");
      script.src = src1;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(fasle);
      };
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    let selectedData = data?.cart.map((item) => {
      return {
        product_id: item.id,
        quantity: item?.newQuantityAdded ? item?.newQuantityAdded : 1,
      };
    });
    const payload = {
      billingDetails: [],
      coupon: { id: data?.coupon?.id },
      productDetails: selectedData,
    };

    axios
      .post(services.getTaxInfo, payload)
      .then((response) => {
        setTaxvalue(response?.data?.calculate_cart_price?.total_payable_amount);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [data?.cart]);
  // Calling user Data API
  const getUser = async () => {
    try {
      const response = await axios.get(services.getUsers);
      if (response?.status !== "error") {
        console.log(response?.data?.data[0]);
        setUserDataMain(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setCouponData({ id: data?.coupon?.id });
    getUser();
  }, []);

  const defaultAddress = {
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    user_street: "",
    user_city: "",
    user_district: "",
    user_state: "Select State",
    user_country: "Select Country",
    user_postcode: "",
    user_shipping_street: "",
    user_shipping_city: "",
    user_shipping_district: "",
    user_shipping_state: "Select State",
    user_shipping_country: "Select Country",
    user_shipping_postcode: "",
  };

  // fetching user Data
  useEffect(() => {
    setInitialData({
      ...defaultAddress,
      first_name: userDataMain[0]?.first_name || "",
      last_name: userDataMain[0]?.last_name || "",
      mobile: userDataMain[0]?.mobile || "",
      email: userDataMain[0]?.email || "",
      // billing address
      user_street: userDataMain[0]?.user_detail?.user_street || "",
      user_city: userDataMain[0]?.user_detail?.user_city || "",
      user_district: userDataMain[0]?.user_detail?.user_district || "",
      user_state: userDataMain[0]?.user_detail?.user_state || "Select State",
      user_country:
        userDataMain[0]?.user_detail?.user_country || "Select Country",
      user_postcode: userDataMain[0]?.user_detail?.user_postcode || "",
      payment_method: "cod",

      // Shipping Address
      user_shipping_street:
        userDataMain[0]?.user_detail?.user_shipping_street || "",
      user_shipping_city:
        userDataMain[0]?.user_detail?.user_shipping_city || "",
      user_shipping_district:
        userDataMain[0]?.user_detail?.user_shipping_district || "",
      user_shipping_state:
        userDataMain[0]?.user_detail?.user_shipping_state || "Select State",
      user_shipping_country:
        userDataMain[0]?.user_detail?.user_shipping_country || "Select Country",
      user_shipping_postcode:
        userDataMain[0]?.user_detail?.user_shipping_postcode || "",
    });
  }, [userDataMain]);

  // Payment intigration
  let pay = {};
  const displayRozarpay = async (input, order = null) => {
    console.log(taxvalue, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("offline");
      return;
    }
    const userDataFinal = {
      name: input.name,
      email: input.email,
      contact: input.tel,
    };

    input.email;
    const options = {
      key: "rzp_live_V6SCjhhEwPUV2j", // Enter the Key ID generated from <br/>the Dashboard
      amount: taxvalue * 100,
      currency: "INR",
      description: `${services.descriptionForPayment} payment of order${order}`,
      image: services.logoInPayment,
      prefill: userDataFinal,
      // order_id : "12312321112221",
      handler: function (response) {
        let paymentPayload = {
          order_data: {
            order_id: order,
            payment_id: response.razorpay_payment_id,
          },
        };
        axios
          .post(services.updatePayment, paymentPayload)
          .then((response) => {
            dispatch(cartDataRemove([]));
            navigate("/thankyou");
          })
          .catch((error) => {
            console.log(error);
          });
      },
      // "modal": {
      //   "ondismiss": function () {
      //     if (confirm("Are you sure, you want to close the form?")) {
      //       txt = "You pressed OK!";
      //       console.log("Checkout form closed by the user");
      //     } else {
      //       txt = "You pressed Cancel!";
      //       console.log("Complete the PaonSubmityment")
      //     }
      //   }
      // }
    };
    let pay = await new window.Razorpay(options);
    console.log(pay);
    pay.open();
  };
  // order Details
  const hitAPIForTheOrderDetails = (input) => {
    console.log(input, ">>>>>>>>>>>>>>>>>>>>>>>>>");
    setLoading(true);
    let selectedData = data?.cart.map((item) => {
      return {
        product_id: item.id,
        quantity: item?.newQuantityAdded ? item?.newQuantityAdded : 1,
      };
    });
    const payload = {
      billingDetails: input,
      coupon: { id: data?.coupon?.id },
      productDetails: selectedData,
    };

    let order_id = 0;
    axios
      .post(services.placeOrder, payload)

      .then((response) => {
        setLoading(false);
        if (response?.data.status === "success") {
          order_id = response?.data?.order_id;
        }
        if (order_id !== 0) {
          if (input?.payment_method === "cod") {
            dispatch(cartDataRemove([]));
            navigate("/thankyou");
          } else {
            displayRozarpay(input, order_id);
          }
        }
        useEffect(() => {
          let selectedData = data?.cart.map((item) => {
            return {
              product_id: item.id,
              quantity: item.newQuantityAdded,
            };
          });
          const payload = {
            billingDetails: [],
            productDetails: selectedData,
          };

          axios
            .post(services.getTaxInfo, payload)
            .then((response) => {
              setTaxinfo(response.data);
              console.log(response, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
              // setCartTotal(response.data.order);
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
            });
        }, [data?.cart]);
      })
      .catch((error) => {
        console.log(error);
      });
    // order API hit or
  };
  // Form Validations
  let billingForm = {};
  if (shipToDifferentAddress) {
    billingForm = Yup.object().shape({
      first_name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Name is Required"),
      last_name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Name is Required"),
      mobile: Yup.number()
        .typeError("Must be a number type")
        .required("Contact No is Required"),
      email: Yup.string()
        .email("Invalid email")
        .required("Please enter correct email"),

      // Billing Address
      user_street: Yup.string()
        .min(2, "Please enter House Details")
        .max(100, "Please enter House Details")
        .required("Please enter House Details"),
      user_city: Yup.string()
        .min(2, "Please enter correct City Details")
        .max(100, "Please enter correct City Details"),
      user_district: Yup.string()
        .min(2, "Please enter correct city Details")
        .max(100, "Please enter correct city Details")
        .required("Please enter  city Details"),
      user_state: Yup.string()
        .min(2, "Please enter correct state Details")
        .max(100, "Please enter correct state Details")
        .required("Please enter state Details"),
      user_country: Yup.string()
        .min(2, "Please enter correct country Details")
        .max(100, "Please enter correct country Details")
        .required("Please enter country Details"),
      user_postcode: Yup.number()
        .min(1000, "zipcode Too Short!")
        .max(9999999, "zip code Too Long!")
        .required("Zipcode Required")
        .typeError("Must be a number type"),

      // Shipping Address
      user_shipping_street: Yup.string()
        .min(2, "Please enter House Details")
        .max(100, "Please enter House Details")
        .required("Please enter House Details"),
      user_shipping_city: Yup.string()
        .min(2, "Please enter correct City Details")
        .max(100, "Please enter correct City Details"),
      user_shipping_district: Yup.string()
        .min(2, "Please enter correct city Details")
        .max(100, "Please enter correct city Details")
        .required("Please enter  city Details"),
      user_shipping_state: Yup.string()
        .min(2, "Please enter correct state Details")
        .max(100, "Please enter correct state Details")
        .required("Please enter state Details"),
      user_shipping_country: Yup.string()
        .min(2, "Please enter correct country Details")
        .max(100, "Please enter correct country Details")
        .required("Please enter country Details"),
      user_shipping_postcode: Yup.number()
        .min(1000, "zipcode Too Short!")
        .max(9999999, "zip code Too Long!")
        .required("Zipcode Required")
        .typeError("Must be a number type"),
    });
  } else {
    billingForm = Yup.object().shape({
      first_name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Name is Required"),
      last_name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Name is Required"),
      mobile: Yup.number()
        .typeError("Must be a number type")
        .required("Contact No is Required"),
      email: Yup.string()
        .email("Invalid email")
        .required("Please enter correct email"),

      // Billing Address
      user_street: Yup.string()
        .min(2, "Please enter House Details")
        .max(100, "Please enter House Details")
        .required("Please enter House Details"),
      user_city: Yup.string()
        .min(2, "Please enter correct City Details")
        .max(100, "Please enter correct City Details"),
      user_district: Yup.string()
        .min(2, "Please enter correct city Details")
        .max(100, "Please enter correct city Details")
        .required("Please enter  city Details"),
      user_state: Yup.string()
        .min(2, "Please enter correct state Details")
        .max(100, "Please enter correct state Details")
        .required("Please enter state Details"),
      user_country: Yup.string()
        .min(2, "Please enter correct country Details")
        .max(100, "Please enter correct country Details")
        .required("Please enter country Details"),
      user_postcode: Yup.number()
        .min(1000, "zipcode Too Short!")
        .max(9999999, "zip code Too Long!")
        .required("Zipcode Required")
        .typeError("Must be a number type"),
    });
  }

  return (
    <>
      <TitleBar
        pagetitle="Checkout"
        breadcrumbs={
          <>
            <Link to="/">Home</Link> &gt;
            <Link to=""> Checkout</Link>
          </>
        }
      />

      {/* <div className="container px-4 flex sm:flex-col lg:flex-row">
                <div className="basis-[70%] px-8 mt-8">
                    <h3>
                        Returning customer?{' '}
                        <span className="text-primary cursor-pointer" onClick={toggleLoginForm}>
                            Click here to login
                        </span>
                    </h3>
                    <div className="pt-6">
                        {showLoginForm && <LoginApi />}
                    </div>
                </div>
            </div> */}

      {/* Form Area */}
      <div className="container px-4 mb-12 flex sm:flex-col lg:flex-row">
        <div className="basis-[70%] py-6 px-8 mt-8 border-2">
          <Formik
            enableReinitialize={true}
            initialValues={initialData}
            validationSchema={billingForm}
            onSubmit={(values) => {
              // same shape as initial values
              //console.log(values);
              hitAPIForTheOrderDetails(values);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="errorName">
                  {/* Personal Details */}
                  {errors.full_name && touched.full_name ? (
                    <div className="errorList">{errors.full_name}</div>
                  ) : null}
                  {errors.last_name && touched.last_name ? (
                    <div className="errorList">{errors.last_name}</div>
                  ) : null}
                  {errors.mobile && touched.mobile ? (
                    <div className="errorList">{errors.mobile}</div>
                  ) : null}
                  {errors.email && touched.email ? (
                    <div className="errorList">{errors.email}</div>
                  ) : null}

                  {/* Billing Address */}
                  {errors.user_street && touched.user_street ? (
                    <div className="errorList">{errors.user_street}</div>
                  ) : null}
                  {errors.user_city && touched.user_city ? (
                    <div className="errorList">{errors.user_city}</div>
                  ) : null}
                  {errors.user_district && touched.user_district ? (
                    <div className="errorList">{errors.user_district}</div>
                  ) : null}
                  {errors.user_state && touched.user_state ? (
                    <div className="errorList">{errors.user_state}</div>
                  ) : null}
                  {errors.user_country && touched.user_country ? (
                    <div className="errorList">{errors.user_country}</div>
                  ) : null}
                  {errors.user_postcode && touched.user_postcode ? (
                    <div className="errorList">{errors.user_postcode}</div>
                  ) : null}
                  {/* shipping Address */}
                  {errors.user_shipping_street &&
                  touched.user_shipping_street ? (
                    <div className="errorList">
                      {errors.user_shipping_street}
                    </div>
                  ) : null}
                  {errors.user_shipping_city && touched.user_shipping_city ? (
                    <div className="errorList">{errors.user_shipping_city}</div>
                  ) : null}
                  {errors.user_shipping_district &&
                  touched.user_shipping_district ? (
                    <div className="errorList">
                      {errors.user_shipping_district}
                    </div>
                  ) : null}
                  {errors.user_shipping_state && touched.user_shipping_state ? (
                    <div className="errorList">
                      {errors.user_shipping_state}
                    </div>
                  ) : null}
                  {errors.user_shipping_country &&
                  touched.user_shipping_country ? (
                    <div className="errorList">
                      {errors.user_shipping_country}
                    </div>
                  ) : null}
                  {errors.user_shipping_postcode &&
                  touched.user_shipping_postcode ? (
                    <div className="errorList">
                      {errors.user_shipping_postcode}
                    </div>
                  ) : null}
                </div>
                {/* Billing Forms */}
                <div>
                  <h3>Billing details</h3>
                  <p className="py-2">
                    Refer By Member (जिस Member ने आपको रेफेर किया है उसकी 6
                    अंको की Member ID डाले){" "}
                  </p>
                  <Field
                    type="text"
                    //   id="name"
                    name="referral_member_id"
                    className="max-w-[49%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                    placeholder="Member ID*"
                    // value={email}
                    // onChange={(e) => setEmail(e.target.value)}
                  ></Field>
                </div>
                {/* Personal Information */}
                <div>
                  <h3 className="py-6">Personal Information</h3>
                  <Field
                    type="text"
                    //   id="name"
                    name="first_name"
                    className="max-w-[49%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                    placeholder="First Name*"
                    required1
                    // value={email}
                    // onChange={(e) => setEmail(e.target.value)}
                  ></Field>

                  <Field
                    type="text"
                    //   id="alt_tel"
                    name="last_name"
                    className="max-w-[49%] ml-[2%]  h-[40px] border-[2px] border-[#e9e9e9] bg-[#e9e9e9] outline-none focus-within:border-primary transition-all text-[#000] text-sm w-full p-2 mb-6"
                    placeholder="Last Name*"
                    required1
                    // value={password}
                    // onChange={(e) => setPassword(e.target.value)}
                  />

                  <Field
                    type="tel"
                    //   id="tel"
                    name="mobile"
                    className="max-w-[49%] h-[40px] border-[2px] border-[#e9e9e9] bg-[#e9e9e9] outlipayment_methodne-none focus-within:border-primary transition-all text-[#000] text-sm w-full p-2 mb-6"
                    placeholder="Mobile No.*"
                    required1
                    // value={password}
                    // onChange={(e) => setPassword(e.target.value)}
                  />

                  <Field
                    type="email"
                    name="email"
                    //   id="emailid"
                    className="max-w-[49%] ml-[2%] h-[40px] border-[2px] border-[#e9e9e9] bg-[#e9e9e9] outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                    placeholder="Email*"
                    required1
                    // value={email}
                    // onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <hr />
                {/* Billing Address */}
                <div>
                  <h3 className="py-6">Billing Address:</h3>
                  <Field
                    type="text"
                    //   id="house_no"
                    name="user_street"
                    className="max-w-[49%] h-[40px] bg-[#e9e9e9] mr-[2%] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                    placeholder="House number and street name*"
                    required1
                    //value={userDataMain[0]?.user_detail?.user_street||''}
                    // onChange={(e) => setEmail(e.target.value)}
                  />

                  <Field
                    type="text"
                    //   id="apartment"
                    name="user_city"
                    className="max-w-[49%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                    placeholder="Village / City*"
                    required1
                    //value={userDataMain[0]?.user_detail?.user_apartment}
                    // onChange={(e) => setEmail(e.target.value)}
                  />

                  <Field
                    type="text"
                    //   id="town_city"
                    name="user_district"
                    className="max-w-[49%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                    placeholder="District*"
                    required1
                    //value={userDataMain[0]?.user_detail?.user_city}
                    // onChange={(e) => setEmail(e.target.value)}
                  />

                  <Field
                    as="select"
                    name="user_state"
                    className="max-w-[49%] ml-[2%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                  >
                    {states.map((states) => (
                      <option
                        key={states}
                        value={states}
                        //selected={((userDataMain[0]?.user_detail?.user_state ===states)?true:false)}
                      >
                        {states}
                      </option>
                    ))}
                  </Field>

                  <Field
                    as="select"
                    name="user_country"
                    className="max-w-[49%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                  >
                    {countries.map((countries) => (
                      <option
                        key={countries}
                        value={countries}
                        //selected={((userDataMain[0]?.user_detail?.user_state ===states)?true:false)}
                      >
                        {countries}
                      </option>
                    ))}
                  </Field>

                  <Field
                    type="tel"
                    //   id="zip_code"
                    name="user_postcode"
                    className="max-w-[49%] h-[40px] ml-[2%] border-[2px] border-[#e9e9e9] bg-[#e9e9e9] outline-none focus-within:border-primary transition-all text-[#000] text-sm w-full p-2 mb-6"
                    placeholder="Zip*"
                    required1
                    //value={userDataMain[0]?.user_detail?.user_postcode}
                    // onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                {/* Shipping Address */}
                <div>
                  <Field
                    className="inline-block mr-2 mb-6"
                    type="checkbox"
                    name="shipToDifferentAddress"
                    checked={shipToDifferentAddress}
                    onChange={(e) =>
                      setShipToDifferentAddress(e.target.checked)
                    }
                  />
                  <label htmlFor="shipToDifferentAddress">
                    Ship to a different address
                  </label>
                  {/* Shipping address form Fields */}
                  {shipToDifferentAddress && (
                    <div className="inline-block">
                      {/* address */}
                      <div>
                        <h3 className="py-6">Shipping Address:</h3>
                        <Field
                          type="text"
                          //   id="house_no"
                          name="user_shipping_street"
                          className="max-w-[49%] h-[40px] bg-[#e9e9e9] mr-[2%] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                          placeholder="House number and street name*"
                          required1
                          //value={userDataMain[0]?.user_detail?.user_street||''}
                          // onChange={(e) => setEmail(e.target.value)}
                        />

                        <Field
                          type="text"
                          //   id="apartment"
                          name="user_shipping_city"
                          className="max-w-[49%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                          placeholder="Village / City*"
                          required1
                          //value={userDataMain[0]?.user_detail?.user_apartment}
                          // onChange={(e) => setEmail(e.target.value)}
                        />

                        <Field
                          type="text"
                          //   id="town_city"
                          name="user_shipping_district"
                          className="max-w-[49%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                          placeholder="District*"
                          required1
                          //value={userDataMain[0]?.user_detail?.user_city}
                          // onChange={(e) => setEmail(e.target.value)}
                        />

                        <Field
                          as="select"
                          name="user_shipping_state"
                          className="max-w-[49%] ml-[2%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                        >
                          {states.map((states) => (
                            <option
                              key={states}
                              value={states}
                              //selected={((userDataMain[0]?.user_detail?.user_state ===states)?true:false)}
                            >
                              {states}
                            </option>
                          ))}
                        </Field>

                        <Field
                          as="select"
                          name="user_shipping_country"
                          className="max-w-[49%] h-[40px] bg-[#e9e9e9] border-[2px] border-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-2 mb-6"
                        >
                          {countries.map((countries) => (
                            <option
                              key={countries}
                              value={countries}
                              //selected={((userDataMain[0]?.user_detail?.user_state ===states)?true:false)}
                            >
                              {countries}
                            </option>
                          ))}
                        </Field>

                        <Field
                          type="tel"
                          //   id="zip_code"
                          name="user_shipping_postcode"
                          className="max-w-[49%] h-[40px] ml-[2%] border-[2px] border-[#e9e9e9] bg-[#e9e9e9] outline-none focus-within:border-primary transition-all text-[#000] text-sm w-full p-2 mb-6"
                          placeholder="Zip*"
                          required1
                          //value={userDataMain[0]?.user_detail?.user_postcode}
                          // onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/* Payment Method checkbox */}
                <div>
                  <h3 className="py-6">Payment Method </h3>
                  <Field
                    as="select"
                    name="payment_method"
                    className="max-w-[100%] h-[40px] border-[2px] border-[#e9e9e9] bg-[#e9e9e9] outline-none focus-within:border-primary transition-all text-[#000] text-sm w-full "
                  >
                    <option value="cod">Cash On Delivery </option>
                    <option value="online">Online Payment</option>
                  </Field>
                  <br />
                  <br />
                  <button
                    type="submit"
                    className="text-white text-xl bg-primary px-12 py-4"
                  >
                    Place Order{" "}
                  </button>
                  {loading && <p className="text-black py-2">Please wait...</p>}
                </div>
              </Form>
            )}
          </Formik>
        </div>

        {/* Checkout Payment Table */}
        <div className="basis-[30%] ml-4 mt-8 ">
          <h3 className="py-2 mb-6 px-2 border-l-2 border-primary">
            Cart Total
          </h3>

          <CartTotal couponData={couponData} />

          {/* <div className='py-8'>
                        <OrderPayment />
                    </div> */}

          {/* <Link to='/checkout'>
                        <div className=" px-5 py-2.5 relative group overflow-hidden font-medium bg-primary text-white text-center block border border-primary cursor-pointer">
                            <span className="absolute top-0 left-0 flex w-[0] h-full mb-0 transition-all duration-700 ease-out transform translate-y-0 bg-white group-hover:w-full "></span>
                            <span className="relative group-hover:text-primary">Place Order</span>
                        </div>
                    </Link> */}
        </div>
      </div>
    </>
  );
};

export default NewCheckout;
