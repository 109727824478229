import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import swal from "sweetalert";

const LoginApi = () => {
  let navigate = useNavigate();

  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [validation, setValidation] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setValidation("");
    setLoading(true);
    try {
      const response = await axios.post("/login", {
        mobile,
        password,
      });
      localStorage.setItem("token", response.data.token);
      swal("You have successfully Logged In!");
      navigate("/shop");
    } catch (error) {
      console.log("Login Failed", error.response.data);
      setValidation(error.response.data);
    }
    setLoading(false);
  };

  //show hide password handlechange
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          id="email"
          className=" h-[60px] border-[2px] border-[#e9e9e9] bg-[#e9e9e9]  outline-none focus-within:border-primary transition-all  text-[#000] text-sm w-full p-6 mb-8"
          placeholder="Mobile No.*"
          required
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
        />
        <div className="relative">
          <input
            id="password"
            className=" h-[60px] border-[2px] border-[#e9e9e9] bg-[#e9e9e9] outline-none focus-within:border-primary transition-all text-[#000] text-sm w-full p-6 mb-8"
            placeholder="Password*"
            required
            value={password}
            type={showPassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span
            className="absolute top-[35%] right-2 transform -translate-y-1/2 cursor-pointer text-primary"
            onClick={handleTogglePassword}
          >
            {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
          </span>
        </div>

        <button
          type="submit"
          className="text-white text-xl bg-primary px-12 py-4"
        >
          Login{" "}
        </button>
        {validation && !Array.isArray(validation) && (
          <p className="text-[red] text-sm mt-2">{validation?.message}</p>
        )}
        {loading && <p className="text-left">Please Wait...</p>}
      </form>
    </>
  );
};

export default LoginApi;
